import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { stationActions } from '../_actions';
import Toast from 'react-bootstrap/Toast'

class DashBoard extends React.Component {

    constructor() {
        super();

        this.state = {
            province: 'กรุงเทพมหานคร',
            station: '',
            data1: [],
            alert: false,
            alert_message: "" 
            
        };
         
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.updateInfo = this.updateInfo.bind(this);
        this.ResetAlert = this.ResetAlert.bind(this);
        this.handleLogOut= this.handleLogOut.bind(this);

        this.AddInfo = this.AddInfo.bind(this);
        this.handleAddUser = this.handleAddUser.bind(this);
        this.handleDelUser = this.handleDelUser.bind(this);
    }
        
    handleLogOut(event) {
      //console.log("Logout Clicked!");
      localStorage.removeItem('user');
      //window.location(true);
      window.location.href = '/';
    }

      handleChange(event) {
        console.log(event.target);
        this.setState({province: event.target.value})
      }


      handleClick(event) {
        console.log(event.target.dataset.value);
        this.setState({province: event.target.dataset.value})
        this.setState({station: ''})
      }

      ResetAlert() {
        this.setState({alert: false})
      }

      updateInfo(postData) {
        var id = postData["id"].value
        var username= postData["username"].value
        var password= postData["password"].value
        var firstname= postData["firstname"].value
        var lastname= postData["lastname"].value
        var admin= postData["admin"].value
        
        //console.log("mystation_id: ", station_id)
        const requestOptions = {
            method: 'POST',
            headers: { 'Authorization': 'Bearer 123456789',
            'Content-Type': 'application/json' },
            body: JSON.stringify({ id, username, password, firstname, lastname, admin })
        };

        const requestGetOptions = {
          method: 'GET',
          headers: {'Authorization': 'Bearer 123456789'}
        };
  
        //console.log("requestOptions: ", requestOptions)
         ;(async () => {
              const response = await fetch(`https://hydromet.rid.go.th/api/updateuser`, requestOptions)
              const json = await response.json();
              //console.log("Update Response:", json )
              this.setState({alert: true, alert_message: json.message})

              try {
                const response = await fetch(`https://hydromet.rid.go.th/api/queryuser`,requestGetOptions);
                const json = await response.json();
                this.setState({ data1: json });
                
                //console.log("data :", this.state.data1[0])
              } catch (error) {
                console.log(error);
              }
            })()
       
    
      }

      AddInfo(postData) {
        
        var username= postData["username"].value
        var password= postData["password"].value
        var firstname= postData["firstname"].value
        var lastname= postData["lastname"].value
        var admin= postData["admin"].value
        
        //console.log("mystation_id: ", station_id)
        const requestOptions = {
            method: 'POST',
            headers: { 'Authorization': 'Bearer 123456789',
            'Content-Type': 'application/json' },
            body: JSON.stringify({ username, password, firstname, lastname, admin })
        };

        const requestGetOptions = {
          method: 'GET',
          headers: {'Authorization': 'Bearer 123456789'}
        };
  
        console.log("requestOptions: ", requestOptions)
         ;(async () => {
              const response = await fetch(`https://hydromet.rid.go.th/api/adduser`, requestOptions)
              const json = await response.json();
              //console.log("Update Response:", json )
              this.setState({alert: true, alert_message: json.message})

              try {
                const response = await fetch(`https://hydromet.rid.go.th/api/queryuser`,requestGetOptions);
                const json = await response.json();
                this.setState({ data1: json });
                
                //console.log("data :", this.state.data1[0])
              } catch (error) {
                console.log(error);
              }
            })()
       
    
      }

      DelUser(UserID) {
        
        //console.log("mystation_id: ", station_id)
        var id = UserID
        const requestOptions = {
            method: 'POST',
            headers: { 'Authorization': 'Bearer 123456789',
            'Content-Type': 'application/json' },
            body: JSON.stringify({ id})
        };

        const requestGetOptions = {
          method: 'GET',
          headers: {'Authorization': 'Bearer 123456789'}
        };
  
        //console.log("requestOptions: ", requestOptions)
         ;(async () => {
              const response = await fetch(`https://hydromet.rid.go.th/api/deluser`, requestOptions)
              const json = await response.json();
              //console.log("Update Response:", json )
              this.setState({alert: true, alert_message: json.message})

              try {
                const response = await fetch(`https://hydromet.rid.go.th/api/queryuser`,requestGetOptions);
                const json = await response.json();
                this.setState({ data1: json });
                
                //console.log("data :", this.state.data1[0])
              } catch (error) {
                console.log(error);
              }
            })()
       
    
      }
        
      handleSubmit(event) {
        this.updateInfo(event.target)
        //console.log(event.target["address"].value);
        //console.log("update click!")
        event.preventDefault();
      }

      handleAddUser(event) {
        this.AddInfo(event.target)
        //console.log(event.target["address"].value);
        event.preventDefault();
      }

      handleDelUser(event) {
        this.DelUser(event.target.dataset.value)
        //console.log("Del User ID:", event.target.dataset.value);
        //event.preventDefault();
      }

    async componentDidMount() {
        //this.props.getStationInfo();
        try {
          const requestOptions = {
            method: 'GET',
            headers: {'Authorization': 'Bearer 123456789'}
          };
          const response = await fetch(`https://hydromet.rid.go.th/api/queryuser`,requestOptions);
          const json = await response.json();
          this.setState({ data1: json });
          //console.log("data :", this.state.data1[0])
        } catch (error) {
          console.log(error);
        }
    }

    render() {
        const { user } = this.props;
        //console.log("stations = " + stations)
        let selected_province = this.state.province
        let unique = []
        let temp = ""
        
        var myobj = []
       
        myobj = this.state.data1

        if (myobj) {
          unique = [...new Set(myobj.map(item => item.province))];
          //myobj = myobj.filter(station => station.province === selected_province)
        }
        
        //console.log("obj filterd:", obj)
        //console.log("my obj:", myobj)
        //console.log("alert :", this.state.alert)
        let tableuser = []

       {myobj &&
              myobj.map((data, index) => {
                 let selectadmin = []
                 if (data.username === "admin") {
                  selectadmin.push( <select  className="form-control form-control-sm" name="admin" disabled="disabled">
                      <option value="0">No</option> 
                      <option selected="selected" value="1">Yes</option> 
                    </select>)
                 } else if (data.admin === 1){
                  selectadmin.push( <select  className="form-control form-control-sm" name="admin" >
                  <option value="0">No</option> 
                  <option selected="selected" value="1">Yes</option> 
                  </select>)
                 } else {
                  selectadmin.push( <select  className="form-control form-control-sm" name="admin" >
                  <option selected="selected" value="0">No</option> 
                  <option  value="1">Yes</option> 
                  </select>)
                 }
                 tableuser.push(
                    <form key={data.station_id} onSubmit={this.handleSubmit}>
                    <table className="table table-fixed">
                 
                    <tbody>
                    <tr key={data.station_id}>
                    <th scope="row" className="col-1"><h6><small>{data.id}</small></h6><input type="hidden" value={data.id} name="id"/></th>
                    <th scope="row" className="col-2">
                    {data.username === "admin" ? 
                                          <input className="form-control input-md" type="text"  style={{fontSize: 12, textAlign: 'center'}} defaultValue={data.username} name="username" disabled/>
                                         : <input className="form-control input-md" type="text"  style={{fontSize: 12, textAlign: 'center'}} defaultValue={data.username} name="username"/>
                                        }
                    </th>
                    <td className="col-2"><input className="form-control input-md" type="password"  style={{fontSize: 12, textAlign: 'center'}} defaultValue={data.password} name="password"/></td>
                    <td className="col-2"><input className="form-control input-md" type="text"  style={{fontSize: 12, textAlign: 'center'}} defaultValue={data.firstname} name="firstname"/></td>
                    <td className="col-2"><input className="form-control input-md" type="text"  style={{fontSize: 12, textAlign: 'center'}} defaultValue={data.lastname} name="lastname" /></td>
                    <td className="col-1">
                   
                      {selectadmin}

                      </td> 
                     <td className="col-1"><button type="submit" className="btn btn-primary btn-sm">แก้ไข</button></td>
                     <td className="col-1">
                     {data.username !== 'admin' ?
                                          <button  type="button" onClick={this.handleDelUser} data-value={data.id} className="btn btn-danger btn-sm">ลบ</button>
                                          : <input type="hidden" value={data.id} name="dummy"/>
                                          }
                    </td>
                    </tr>
                    </tbody>
                    </table>
                    </form>
                )
                 })

              }
          
      

        return (

          <Fragment> 

            <nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-primary">
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
                    <a className="navbar-brand" href="#">Telemetry Report</a>
                    <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
                        <li className="nav-item">
                            <a className="nav-link active" href="./">หน้าหลัก</a>
                        </li>
                        <li className="nav-item dropdown">
                        <a className="nav-link active dropdown-toggle" data-toggle="dropdown" href="#">จัดการ</a>
                            <div className="dropdown-menu">
                                <a className="dropdown-item" href="./edit">ข้อมูลสถานี</a>
                                <a className="dropdown-item" href="./edituser">ผู้ใช้งาน</a>
                            </div>
                        </li>
                    </ul>
    
                </div>
                <div >
                    <ul className="nav nav-pills mr-4">
                    <div className="btn-group dropdown">
                                     <button type="button" className="btn btn-primary dropdown-toggle" data-toggle="dropdown">
                                     <img className="img-circle" height="30px" src={require('../img/ico_usr.png')}></img>
                                         {user.username}
                                         </button>
                                    
                                             <div className="dropdown-menu">
                                               <a className="dropdown-item" key={0} onClick={this.handleLogOut} data-value="ทั้งหมด" href="#">Logout</a>
                                              
                                             </div>   
                                           
                                 </div>
                    </ul>
                </div>
            </nav>

            <div className="container pt-4">
            </div>
            
            <div className="container pt-5">
            <div className="row pb-8">
            <Toast onClose={() => this.ResetAlert()} show={this.state.alert} delay={3000} autohide style={{
      position: 'absolute',
      bottom: 15,
      right: 15,
      zIndex: 1000,
    }}>
              <Toast.Header>
                <strong className="mr-auto">แจ้งเตือน</strong>
                <small></small>
              </Toast.Header>
              <Toast.Body>{this.state.alert_message}</Toast.Body>
            </Toast>
            <div className="col-lg-12 mx-0 bg-white rounded shadow">
                
            
            
                <div className="row mb-2">

                    <div className="col-lg-5 mx-0 ">
                    
                        <ul className="nav nav-pills">
                            <li className="nav-item m-2">
                            <div className="btn-group dropdown">
                                <button type="button" className="btn btn-primary " >ผู้ใช้งาน</button>  
                            </div>
                            </li>
                            
                        </ul>
                    </div>
                    
                    
                
                </div>  

            <div className="row mx-auto" >
            <div className="table-responsive">
                  <table className="table table-fixed mr-3">
                      <thead>
                        <tr>
                            <th scope="col" className="col-1"><h6><small>#</small></h6></th>
                            <th scope="col" className="col-2"><h6><small>username</small></h6></th>
                            <th scope="col" className="col-2"><h6><small>password</small></h6></th>
                            <th scope="col" className="col-2"><h6><small>firstname</small></h6></th>
                            
                            <th scope="col" className="col-2"><h6><small>lastname</small></h6></th>
                            <th scope="col" className="col-1"><h6><small>admin</small></h6></th>
                            <th scope="col" className="col-1"><h6><small>Action</small></h6></th>
                            <th scope="col" className="col-1"><h6><small>Action</small></h6></th>
                          </tr>
                            
                        </thead>
                  </table>
                                        <form key="addUser" onSubmit={this.handleAddUser}> 
                                          <table className="table table-fixed">
                                          <tbody>
                                          <tr>
                                            
                                            
                                          <th scope="row" className="col-1"><h6><small>#</small></h6><input type="hidden" name="id"/></th>
                                          <th scope="row" className="col-2"> <input className="form-control input-md" type="text"  style={{fontSize: 12, textAlign: 'center'}}  name="username" />
                                          
                                            </th>
                                          <td className="col-2"><input className="form-control input-md" type="password"  style={{fontSize: 12, textAlign: 'center'}} name="password"/></td>
                                          <td className="col-2"><input className="form-control input-md" type="text"  style={{fontSize: 12, textAlign: 'center'}} name="firstname"/></td>
                                          
                                          <td className="col-2"><input className="form-control input-md" type="text"  style={{fontSize: 12, textAlign: 'center'}} name="lastname" /></td>
                                          <td className="col-1">
                                          
                                          <select  className="form-control form-control-sm" name="admin">
                                            <option value="0">No</option>
                                            <option value="1">Yes</option>
                                          </select>
                                          </td> 
                                          <td className="col-1"><button type="submit" className="btn btn-success btn-sm">เพิ่ม</button></td>
                                          <td className="col-1"></td>
                                          </tr>
                                          </tbody>
                           
                                          </table>
                                      </form>
                  <div className="custom-scrollbar-css">
                 
                               
                                {tableuser}
                                      
                     
                  </div>
                            
             </div>           
        
                         
           </div>    
                          
            </div>

         
            </div>
            </div>
              
            </Fragment> 
        );

        
    }
}

function mapState(state) {
    const { authentication } = state;
    const { user } = authentication;
    return { user};
}

const actionCreators = {
    //getStationInfo: stationActions.getStationInfo
}

const connectedDashboard = connect(mapState, actionCreators)(DashBoard);
export { connectedDashboard as UserEdit };