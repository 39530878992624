import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { stationActions } from '../_actions';
import Toast from 'react-bootstrap/Toast'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

class DashBoard extends React.Component {

    constructor() {
        super();

        this.state = {
            province: 'กรุงเทพมหานคร',
            station: '',
            data1: '',
            alert: false,
            alert_message: "" 
            
        };
         
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.updateInfo = this.updateInfo.bind(this);
        this.ResetAlert = this.ResetAlert.bind(this);
        this.handleLogOut= this.handleLogOut.bind(this);
    }
        
    handleLogOut(event) {
      //console.log("Logout Clicked!");
      localStorage.removeItem('user');
      //window.location(true);
      window.location.href = '/';
    }

      handleChange(event) {
        console.log(event.target);
        this.setState({province: event.target.value})
      }


      handleClick(event) {
        console.log(event.target.dataset.value);
        this.setState({province: event.target.dataset.value})
        this.setState({station: ''})
      }

      ResetAlert() {
        this.setState({alert: false})
      }

      updateInfo(postData) {
        var station_id= postData["station_id"].value
        var name= postData["name"].value
        var address= postData["address"].value
        var device_sn= postData["device_sn"].value
        var latitude= postData["latitude"].value
        var longtitude= postData["longtitude"].value
        //console.log("mystation_id: ", station_id)
        const requestOptions = {
            method: 'POST',
            //headers: authHeader(),
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ station_id, name, address, device_sn, latitude, longtitude })
        };
  
        console.log("requestOptions: ", requestOptions)
         ;(async () => {
              const response = await fetch(`https://hydromet.rid.go.th/api/updatestation`, requestOptions)
              const json = await response.json();
              console.log("Update Response:", json )
              this.setState({alert: true, alert_message: json.message})

              try {
                const response = await fetch(`https://hydromet.rid.go.th/api/querystationall`);
                const json = await response.json();
                this.setState({ data1: json });
                
                //console.log("data :", this.state.data1[0])
              } catch (error) {
                console.log(error);
              }
            })()
        /*fetch(`https://hydromet.rid.go.th/api/updatestation`, requestOptions).then(response => response.json())
        .then(data => 
          { console.log("Update Response:", data )
            this.setState({alert: true, alert_message: "Update Successfull"})
          }
          );/*
       
         

          /*
        try {
          const response = fetch(`https://hydromet.rid.go.th/api/updatestation`, requestOptions);
          const json = response.json();
          console.log("Json Return:", )
          //this.setState({ data1: json });
          //console.log("data :", this.state.data1[0])
        } catch (error) {
          console.log(error);
        } */
    
      }
        
      handleSubmit(event) {
        this.updateInfo(event.target)
        console.log(event.target["address"].value);
        event.preventDefault();
      }

    async componentDidMount() {
        //this.props.getStationInfo();
        try {
          const response = await fetch(`https://hydromet.rid.go.th/api/querystationall`);
          const json = await response.json();
          this.setState({ data1: json });
          
          //console.log("data :", this.state.data1[0])
        } catch (error) {
          console.log(error);
        }
    }

    render() {
        const { user } = this.props;
        //console.log("stations = " + stations)
        let selected_province = this.state.province
        let unique = []
        

       
        var myobj = this.state.data1

        if (myobj) {
          unique = [...new Set(myobj.map(item => item.province))];
          myobj = myobj.filter(station => station.province === selected_province)
        }
        
        //console.log("obj filterd:", obj)
        //console.log("my obj:", myobj)
        console.log("alert :", this.state.alert)
        

        return (

          <Fragment> 

            <nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-primary">
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
                    <a class="navbar-brand" href="#">Telemetry Report</a>
                    <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
                        <li class="nav-item">
                            <a class="nav-link active" href="./">หน้าหลัก</a>
                        </li>
                        <li class="nav-item dropdown">
                        <a class="nav-link active dropdown-toggle" data-toggle="dropdown" href="#">จัดการ</a>
                            <div class="dropdown-menu">
                                <a class="dropdown-item" href="./edit">ข้อมูลสถานี</a>
                                <a className="dropdown-item" href="./edituser">ผู้ใช้งาน</a>
                            </div>
                        </li>
                    </ul>
    
                </div>
                <div >
                    <ul className="nav nav-pills mr-4">
                    <div className="btn-group dropdown">
                                     <button type="button" className="btn btn-primary dropdown-toggle" data-toggle="dropdown">
                                     <img className="img-circle" height="30px" src={require('../img/ico_usr.png')}></img>
                                         {user.username}
                                         </button>
                                    
                                             <div className="dropdown-menu">
                                               <a className="dropdown-item" key={0} onClick={this.handleLogOut} data-value="ทั้งหมด" href="#">Logout</a>
                                              
                                             </div>   
                                           
                                 </div>
                    </ul>
                </div>
            </nav>

            <div className="container pt-4">
            </div>
            
            <div className="container pt-5">
            <div className="row pb-8">
            <Toast onClose={() => this.ResetAlert()} show={this.state.alert} delay={3000} autohide style={{
      position: 'absolute',
      bottom: 15,
      right: 15,
      zIndex: 1000,
    }}>
              <Toast.Header>
                <strong className="mr-auto">แจ้งเตือน</strong>
                <small></small>
              </Toast.Header>
              <Toast.Body>{this.state.alert_message}</Toast.Body>
            </Toast>
            <div className="col-lg-12 mx-0 bg-white rounded shadow">
                
            
            
                <div className="row mb-2">

                    <div className="col-lg-5 mx-0 ">
                    
                        <ul className="nav nav-pills">
                            <li className="nav-item m-2">
                            <div className="btn-group dropdown">
                                <button type="button" className="btn btn-primary dropdown-toggle" data-toggle="dropdown">{this.state.province}</button>
                                {unique &&
                                        <div className="dropdown-menu">
                                          {unique.map((province, index) =>
                                            <a className="dropdown-item" key={index} onClick={this.handleClick} data-value={province} href="#">{province}</a>
                                            
                                          )}
                                        </div>   
                                      } 
                                
                                
                            </div>
                            </li>
                            
                        </ul>
                    </div>
                    
                    
                
                </div>  

            <div className="row mx-auto" >
            <div className="table-responsive">
                  <table className="table table-fixed mr-3">
                      <thead>
                        <tr>
                            <th scope="col" className="col-1"><h6><small>#</small></h6></th>
                            <th scope="col" className="col-2"><h6><small>สถานี</small></h6></th>
                            <th scope="col" className="col-2"><h6><small>ที่อยู่</small></h6></th>
                            <th scope="col" className="col-2"><h6><small>S/N</small></h6></th>
                            
                            <th scope="col" className="col-2"><h6><small>lattitude</small></h6></th>
                            <th scope="col" className="col-2"><h6><small>longtitude</small></h6></th>
                            <th scope="col" className="col-1"><h6><small>Action</small></h6></th>
                          </tr>
                            
                        </thead>
                  </table>

                  <div className="custom-scrollbar-css">
                 
                               
                                {myobj &&
                                    <Fragment>
                                        {myobj.map((data, index) =>
                                           
                                          <form key={data.station_id} onSubmit={this.handleSubmit}> 
                                          <table className="table table-fixed">
                                          <tbody>
                                          <tr key={data.station_id}>
                                            
                                            
                                          <th scope="row" className="col-1"><h6><small>{data.station_id}</small></h6><input type="hidden" value={data.station_id} name="station_id"/></th>
                                          <th scope="row" className="col-2"><textarea  className="form-control"  style={{fontSize: 12, textAlign: 'center'}} defaultValue={data.name} name="name" /></th>
                                          <td className="col-2"><textarea className="form-control" style={{fontSize: 12, textAlign: 'center'}} defaultValue={data.address} name="address" /></td>
                                          <td className="col-2"><input className="form-control input-md" type="text"  style={{fontSize: 12, textAlign: 'center'}} defaultValue={data.sn} name="device_sn"/></td>
                                          
                                          <td className="col-2"><input className="form-control input-md" type="text"  style={{fontSize: 12, textAlign: 'center'}} defaultValue={data.latitude} name="latitude" /></td>
                                          <td className="col-2"><input className="form-control input-md" type="text"  style={{fontSize: 12, textAlign: 'center'}} defaultValue={data.longtitude} name="longtitude" /></td> 
                                          <td className="col-1"><button type="submit" className="btn btn-primary btn-sm">Update</button></td>
                                         
                                          </tr>
                                          </tbody>
                           
                                          </table>
                                          </form>
                                        )}
                                     </Fragment> 
                                 }
                     
                  </div>
                            
             </div>           
        
                         
           </div>    
                          
            </div>

         
            </div>
            </div>
              
            </Fragment> 
        );

        
    }
}

function mapState(state) {
    const { authentication } = state;
    const { user } = authentication;
    return { user};
}

const actionCreators = {
    //getStationInfo: stationActions.getStationInfo
}

const connectedDashboard = connect(mapState, actionCreators)(DashBoard);
export { connectedDashboard as StationEdit };