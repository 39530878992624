import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import MapContainer from '../_googlemaps/map';
import { stationActions } from '../_actions';

import Chart  from "chart.js";
import classes from "./LineGraph.module.css";
import { supportsGoWithoutReloadUsingHash } from 'history/DOMUtils';

import moment from 'moment'

export default class LineGraph extends React.Component {
    chartRef = React.createRef();
    
    componentDidMount() {
        const myChartRef = this.chartRef.current.getContext("2d");
        
        new Chart(myChartRef, {
            type: "bar",
            data: {
                //Bring in data
                labels: ["20:15", "20:30", "20:45", "21:00", "21:15", "21:30"],
                datasets: [
                    {
                        label: "Temperature",
                        data: [31.5, 32, 31.8, 32.2, 32.1, 31.8],
                    }
                ]
            },
            options: {
                //Customize chart options
            }
        });
    }
    render() {
        return (
            <div className={classes.graphContainer}>
                <canvas
                    id="myChart"
                    ref={this.chartRef}
                />
            </div>
        )
    }
}


class DashBoard extends React.Component {

    constructor() {
        super();

        this.state = {
            province: 'กรุงเทพมหานคร',
            station: '',
            delay: false,
            active: true,
            rain: false,
            all_station: []
        };
         
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handledelayClick = this.handledelayClick.bind(this);
        this.handleActiveClick = this.handleActiveClick.bind(this);
        this.handleRainClick = this.handleRainClick.bind(this);
    }
        

      handleChange(event) {
        console.log(event.target);
        this.setState({province: event.target.value})
      }


      handleClick(event) {
        console.log(event.target.dataset.value);
        this.setState({province: event.target.dataset.value})
        this.setState({station: ''})
      }

      handledelayClick(event) {
        console.log("Delay Clicked!");
        console.log(event.target);
        this.setState({delay: true})
        this.setState({rain: false})
        this.setState({active: false})
      }

      handleActiveClick(event) {
        console.log("Delay Clicked!");
        console.log(event.target);
        this.setState({active: true})
        this.setState({delay: false})
        this.setState({rain: false})
      }
      handleRainClick(event) {
        console.log("Delay Clicked!");
        console.log(event.target);
        this.setState({rain: true})
        this.setState({active: false})
        this.setState({delay: false})
      }
        
      handleSubmit(event) {
        console.log(this.state);
        event.preventDefault();
      }
    
    componentWillUnmount(){
        
    }

    componentDidMount() {
        //this.props.getStationInfo();

        const requestOptions = {
            method: 'GET',
            //headers: authHeader()
        };
    
        fetch(`https://hydromet.rid.go.th/api/queryallstation`, requestOptions).then(response => response.json())
      .then(data => this.setState({ all_station: data }));
    }

    render() {
        const { user, stations } = this.props;
        //console.log("stations = " + stations)
        let selected_province = this.state.province
        let unique = []
        //const {user} = this.props 

        const {all_station} = this.state.all_station
        /*
        var x = 90
        x = (((( ( (x-1) - ((x-1) % 10) ) ) / 10) ) % 7) + 1
        console.log("test =", x )
        */

        var items = null ;
        var obj = []
        var obj2 = []
        
        let active_count = 0
        let total_count = 103
        let delay_count = 0
        let rain_count = 0
        let current_time = moment(Date()) 
        /*
        if (stations.items) { 
            items = JSON.stringify(stations.items)
            obj = JSON.parse(items)
            active_count = obj.length
            console.log("active count:", active_count)
        }*/

        if (this.state.all_station) { 
            items = JSON.stringify(this.state.all_station)
            obj = JSON.parse(items)
            active_count = obj.length
            console.log("active count:", active_count)
        }

        //console.log("all_station:", this.state.all_station)
        //console.log("stations:", all_station)

        if (obj) {

            if (this.state.active) {
                obj2 =  obj.filter(station => current_time.diff(moment(station.time), "minutes", true) < 720)
            } else if (this.state.delay) {
                obj2 =  obj.filter(station => current_time.diff(moment(station.time), "minutes", true) >= 720)
            } else if (this.state.rain) {
                obj2 =  obj.filter(station => station.rain > 0)
            }
            else {
                obj2 = obj
            }

            unique = [...new Set(obj.map(item => item.province))];
            //tmp_obj = obj.filter(station => current_time.diff(moment(station.time), "minutes", true) > 120)
            rain_count = obj.filter(station => station.rain > 40).length
            //rain_obj = obj.filter(station => station.rain > 0)
            delay_count = obj.filter(station => current_time.diff(moment(station.time), "minutes", true) > 720).length
            if (selected_province != "ทั้งหมด") {
                obj = obj.filter(station => station.province === selected_province)
            }
        }
        let test_time = null
        
        test_time = current_time.diff(moment("2020-09-03 09:39:04+07"), "minutes", true);
        

        

        //console.log("user:", user)

        return (
            <Fragment> 

            <nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-primary">
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
                    <a class="navbar-brand" href="#">Telemetry Report</a>
                    <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
                        <li class="nav-item">
                            <a class="nav-link active" href="./">หน้าหลัก</a>
                        </li>
                        <li class="nav-item dropdown">
                        <a class="nav-link active dropdown-toggle" data-toggle="dropdown" href="#">จัดการ</a>
                            <div class="dropdown-menu">
                                <a class="dropdown-item" href="./edit">ข้อมูลสถานี</a>
                            </div>
                        </li>
                    </ul>
    
                </div>
                <div >
                    <ul className="nav nav-pills mr-4">
                        <li class="nav-item dropdown">
                        <a class="nav-link active dropdown-toggle" data-toggle="dropdown" href="#"><img className="img-circle" height="30px" src={require('../img/ico_usr.png')}></img>
                                {user.username}</a>
                            <div class="dropdown-menu">
                                <a class="dropdown-item" href="#">ส่วนตัว</a>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>

            <div className="container pt-4">
            </div>
            
            <div className="container pt-5">
            <div className="row pb-8">

            <div className="col-lg-12 mx-0 bg-white rounded shadow">
                

                <div className="row mb-2">

                    <div className="col-lg-5 mx-0 ">
                    
                        <ul className="nav nav-pills">
                            <li className="nav-item m-2">
                            <div className="btn-group dropdown">
                                <button type="button" className="btn btn-primary dropdown-toggle" data-toggle="dropdown">{this.state.province}</button>
                                {unique &&
                                        <div className="dropdown-menu">
                                          <a className="dropdown-item" key={0} onClick={this.handleClick} data-value="ทั้งหมด" href="#">ทั้งหมด</a>
                                          {unique.map((province, index) =>
                                            <a className="dropdown-item" key={index} onClick={this.handleClick} data-value={province} href="#">{province}</a>
                                            
                                          )}
                                        </div>   
                                      } 
                                
                                
                            </div>
                            </li>
                        </ul>
                    </div>
                    
                    <div className="col-lg-4 ml-auto justify-content-end">
                        <ul className="nav nav-pills">
                            <li className="nav-item m-2">
                                <button type="button" onClick={this.handleActiveClick} className="btn btn-success">
                                    ปกติ <span className="badge badge-light">{active_count - delay_count}</span>
                                    <span className="sr-only">unread messages</span>
                                </button>
                            </li>
                            <li className="nav-item m-2">
                                <button type="button" onClick={this.handleRainClick} className="btn btn-warning">
                                    ฝนตก <span className="badge badge-light">{rain_count}</span>
                                    <span className="sr-only">unread messages</span>
                                </button>
                            </li>
                            <li className="nav-item m-2">
                                <button type="button" onClick={this.handledelayClick} className="btn btn-secondary">
                                    ล่าช้า <span className="badge badge-light">{delay_count}</span>
                                    <span className="sr-only">unread messages</span>
                                </button>
                            </li>
                            
                        </ul>
                    </div>
                
                </div>

              
                    
                < MapContainer Province={selected_province} obj={obj} obj2={obj2} />
        
                         
               
                          
            </div>


            </div>
            </div>
            </Fragment> 
        );

        
    }
}

function mapState(state) {
    const { stations, authentication } = state;
    const { user } = authentication;
    return { user, stations };
}

const actionCreators = {
    getStationInfo: stationActions.getStationInfo
}

const connectedDashboard = connect(mapState, actionCreators)(DashBoard);
export { connectedDashboard as DashBoard };