import React, { useState, useEffect } from 'react'
import { GoogleMap, LoadScript, InfoBox} from '@react-google-maps/api';
import axios from 'axios';
import InfoWindowMap from './infobox'
import moment from 'moment'
//import InfoWindowMap2 from './infobox'

import Chart  from "chart.js";
import { Hidden } from '@material-ui/core';
 
const containerStyle = {
  width: '100%',
  height: '100%',
  position: "relative",
  margin: '3'
};

const Colstyle = {
  overflow: Hidden
};

const center = {
    lat: 16.2224,
    lng: 100.475
};

const circle = {
  width: 20,
  height: 20,
  borderRadius: 10,
  borderWidth: 1,
  borderColor: 'blue',
  backgroundColor: "Grey"
};

export class BarGraph extends React.Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  componentDidUpdate() {
    var datax = []
    //var graph_type = 'line'
    //console.log("typelog:", this.props.logtype)
    if(this.props.logtype === 'humid') {
      datax = this.props.data.map(d => d.humid)
    } else if (this.props.logtype === 'solar'){
      datax = this.props.data.map(d => d.solar)
    } else if (this.props.logtype === 'rain'){
      //graph_type = 'bar'
      datax = this.props.data.map(d => d.rain)
    } else if (this.props.logtype === 'wind'){
      datax = this.props.data.map(d => d.wind_speed)
    } else if (this.props.logtype === 'gatecounter'){
      datax = this.props.data.map(d => d.gatecounter)
    } else if (this.props.logtype === 'vsupply'){
      datax = this.props.data.map(d => d.vsupply)
    }
    else {
      datax = this.props.data.map(d => d.temp)
    }
    this.myChart.data.labels = this.props.data.map(d => d.hours);
    this.myChart.type = 'bar'
    //this.myChart.data.datasets[0].data = this.props.data.map(d => d.temp);
    this.myChart.data.datasets[0].data = datax
    this.myChart.data.datasets[0].label = this.props.title
    this.myChart.update();
  }

  get_Data(logtype){
    var datax = []
    if(logtype === 'humid') {
      datax = this.props.data.map(d => d.humid)
    } else if (logtype === 'solar'){
      datax = this.props.data.map(d => d.solar)
    } else if (logtype === 'rain'){
      //graph_type = 'bar'
      datax = this.props.data.map(d => d.rain)
    } else if (logtype === 'wind'){
      datax = this.props.data.map(d => d.wind_speed)
    } else if (logtype === 'gatecounter'){
      datax = this.props.data.map(d => d.gatecounter)
    } else if (logtype === 'vsupply'){
      datax = this.props.data.map(d => d.vsupply)
    }
    else {
      datax = this.props.data.map(d => d.temp)
    }

    return datax 
  }

  componentDidMount() {
    //const datax = this.props.data.map(d => d.temp)
    const datax = this.get_Data(this.props.logtype)
    this.myChart = new Chart(this.canvasRef.current, {
      type: 'bar',
      options: {
              maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              type: 'time',
              time: {
                //parser: 'MM-DD-YYYYTHH:mm',
                tooltipFormat: 'll HH:mm',
                unit: 'hour',
                unitStepSize: 3,
                displayFormats: {
                  'hour': 'HH:mm'
                },
              },
                ticks: {
                    major: {
                       enabled: true, // <-- This is the key line
                       fontStyle: 'bold', //You can also style these values differently
                       fontSize: 14 //You can also style these values differently
                    },
                }
            }],
          yAxes: [
            {
              ticks: {
                min: 0,
                stepSize: 10,
              }
            }
          ]
        }
      },
      data: {
        labels: this.props.data.map(d => d.hours),
        datasets: [{
          label: this.props.title,
          //data: this.props.data.map(d => d.temp),
          data: datax,
          fill: 'none',
          backgroundColor: this.props.color,
          pointRadius: 2,
          borderColor: this.props.color,
          borderWidth: 1,
          lineTension: 0
        }]
      }
    });
  }

  render() {
    
    return (
      
        <canvas ref={this.canvasRef} />
      
    )
    }
}


export class LineGraph extends React.Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }
  
  get_Data(logtype){
    var datax = []
    if(logtype === 'humid') {
      datax = this.props.data.map(d => d.humid)
    } else if (logtype === 'solar'){
      datax = this.props.data.map(d => d.solar)
    } else if (logtype === 'rain'){
      //graph_type = 'bar'
      datax = this.props.data.map(d => d.rain)
    } else if (logtype === 'wind'){
      datax = this.props.data.map(d => d.wind_speed)
    } else if (logtype === 'gatecounter'){
      datax = this.props.data.map(d => d.gatecounter)
    } else if (logtype === 'vsupply'){
      datax = this.props.data.map(d => d.vsupply)
    }
    else {
      datax = this.props.data.map(d => d.temp)
    }

    return datax 
  }

  componentDidUpdate() {
    var datax = this.get_Data(this.props.logtype)
   
    this.myChart.data.labels = this.props.data.map(d => d.hours);
    this.myChart.type = 'bar'
    //this.myChart.data.datasets[0].data = this.props.data.map(d => d.temp);
    this.myChart.data.datasets[0].data = datax
    this.myChart.data.datasets[0].label = this.props.title
    this.myChart.update();
  }

  componentDidMount() {
    //const datax = this.props.data.map(d => d.temp)
    const datax = this.get_Data(this.props.logtype)
    this.myChart = new Chart(this.canvasRef.current, {
      type: 'line',
      options: {
              maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              type: 'time',
              time: {
                //parser: 'MM-DD-YYYYTHH:mm',
                tooltipFormat: 'll HH:mm',
                unit: 'hour',
                unitStepSize: 3,
                displayFormats: {
                  'hour': 'HH:mm'
                },
              },
                ticks: {
                    major: {
                       enabled: true, // <-- This is the key line
                       fontStyle: 'bold', //You can also style these values differently
                       fontSize: 14 //You can also style these values differently
                    },
                }
            }],
          yAxes: [
            {
              ticks: {
                min: 0,
                stepSize: 10,
              }
            }
          ]
        }
      },
      data: {
        labels: this.props.data.map(d => d.hours),
        datasets: [{
          label: this.props.title,
          //data: this.props.data.map(d => d.temp),
          data: datax,
          fill: 'none',
          backgroundColor: this.props.color,
          pointRadius: 2,
          borderColor: this.props.color,
          borderWidth: 1,
          lineTension: 0
        }]
      }
    });
  }

  render() {
    
    return (
      
        <canvas ref={this.canvasRef} />
      
    )
    }
}

// BarChart
class BarChart extends React.Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  componentDidUpdate() {
    this.myChart.data.labels = this.props.data.map(d => d.label);
    this.myChart.data.datasets[0].data = this.props.data.map(d => d.value);
    this.myChart.update();
  }

  componentDidMount() {
    this.myChart = new Chart(this.canvasRef.current, {
      type: 'bar',
      options: {
          maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                min: 0,
                max: 100
              }
            }
          ]
        }
      },
      data: {
        labels: this.props.data.map(d => d.label),
        datasets: [{
          label: this.props.title,
          data: this.props.data.map(d => d.value),
          backgroundColor: this.props.color
        }]
      }
    });
  }

  render() {
    return (
        <canvas ref={this.canvasRef} />
    );
  }
}



function MyComponent({Province, obj, unique, indexOpen, resetClick}) {

  let items = [] ;
  
  const [sensorType, setSensorType] = React.useState("rain")
  const [HideLegend, setHideLegend] = React.useState(false)
  const [sensorDuration, setsensorDuration] = React.useState("60")
  const [sensorName, setSensorName] = React.useState("ฝนราย ชม.")
  const [StationID, setStationID] = React.useState("")
  const [SortType, setSortType] = React.useState("rain")
  
  
  useEffect(()=>{
    console.log(HideLegend);
    //output 'sidebar' 
    //output user-side-bar close
    },[HideLegend]);
  
  const [indexClick, setData] = useState(null)
  const [StationData, setStationData] = React.useState([])
  const [StationData1d, setStationData1d] = React.useState([])
  var load = false 
  let current_time = moment(Date())
  const handleReset = React.useCallback(function callback() {
    setData(null)
  }, [])

  function getInfo(station_id) {

  
    
    var log_type = sensorDuration
    var date_start = ''
    var date_end = ''
   
    var mydata = []
    var requestOptions = {
        method: 'POST',
        //headers: authHeader(),
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ station_id, log_type, date_start, date_end })
    };
  
    //console.log("requestOptions: ", requestOptions)
    
    fetch(`https://hydromet.rid.go.th/api/querylog`, requestOptions).then(response => response.json())
    .then(data1 => setStationData(data1));

    log_type = 1440
    //date_end = moment(Date()).format('YYYY-MM-DDTHH:mm')
    date_end = moment(Date()).format('YYYY-MM-DDTHH:mm')
     date_start  = moment().subtract(7, 'days').format('YYYY-MM-DDTHH:mm');
    requestOptions = {
      method: 'POST',
      //headers: authHeader(),
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ station_id, log_type, date_start, date_end })
    };

  //console.log("requestOptions: ", requestOptions)
  
  fetch(`https://hydromet.rid.go.th/api/querylog`, requestOptions).then(response => response.json())
  .then(data => setStationData1d(data));
  
  
  }

 

  const [stationClick, setStation] = useState(null)
  const [provinceClick, setProvince] = useState(null)
  const mapStationClick = React.useCallback(function callback(StationName, ProvinceName, StationID) {
    setStation(StationName)
    setProvince(ProvinceName)
    //setStationData(getData())
    getInfo(StationID)
  }, [])

  function handleSensorChange(name, duration, type) {
    //console.log(station)
    setSensorName(name)
    setSensorType(type)
    setsensorDuration(duration)
  }

  function handleSortTypeClick(type) {
    //console.log(station)
    setSortType(type)
  }

  function handleLegendClick(status) {
    //console.log(status)
    setHideLegend(status)
  }

  function handleStationChange(station, station_id) {
    //console.log(station)
    setStation(station)
    setStationID(station_id)
  }

  function handleProvinceChange(province) {
    //console.log(province)
    setProvince(province)
   
  }
  var StationView = []
  //console.log("StationData:", StationData )
  if(StationData.length > 0 && StationData1d.length > 0) {
          load =true 
          if (sensorDuration === "60") {
            StationView = StationData
          } else {
            StationView = StationData1d
          }
          //console.log("StationData:", StationData)
          //console.log("StationData1D:", StationData1d)
      }
  
  //console.log("sensorDuration:", sensorDuration)
  if (obj) {  
    if (SortType === "rain") {
      obj = obj.sort(function(a, b){return b.rain1d - a.rain1d}) } 
    else {
      obj = obj.sort(function(a, b){return a.station_id - b.station_id})
      }
      unique = [...new Set(obj.map(item => item.province))];
  }

  {obj &&
    
    obj.map((station1, index) => {
      
      var station = obj[obj.length - 1 - index]
      //console.log("rain 1d:", station.rain1d)
      //Fake Time
      /*
      if (current_time.diff(moment(station.time), "minutes", true) > 120) {
        station.time = current_time
      }
      */
      //
      
      items.push(
        <InfoWindowMap
        myindex={index}
        key={station.station_id} 
        station_id={station.station_id}
        index={station.station_id} 
        lat={station.latitude} lng={station.longtitude} 
        rain={station.rain1d}
        label={station.name} 
        last={current_time.diff(moment(station.time), "minutes", true)}
        address={station.address}
        indexopen={indexClick}
        handleReset={handleReset}
        mapStationClick={mapStationClick}
        mystation={station}
        />
         
        )
      
    })}

    
 
    const handleStationClick = React.useCallback(function callback(index) {
      setData(index)
      //console.info('index:', index)
      //console.info("indexClick", indexClick)
    }, [])
    //console.log("Province: ", Province) ;
    //console.log("unique:", unique)
    let province = '' 
    let tables = [] 
    {unique &&
     
        unique.map((item, index) => {
          tables.push(<tr key={"province" + index} style={{borderBottom: '1px solid #cacaca'}}><td colSpan="12" style={{textAlign: 'center', backgroundColor: '#DDEEFF'}}><h6><small><strong>{item}</strong></small></h6></td></tr>)
          {obj &&
            obj.filter(station => station.province === item).map((station, index) =>
            {
             {  
                if (station.pressure > 700 && station.pressure < 1300) {
                    var pressure = station.pressure
                    if (station.temp >= 0) { 
                      var temp = station.temp
                    } else {
                      var temp = "ปิด"
                    }

                    if (station.humid >= 0) { 
                      var humid= station.humid
                    } else {
                      var humid = "ปิด"
                    }

                    if (station.solar >= 0) { 
                      var solar= station.solar
                    } else {
                      var solar = "ปิด"
                    }
                } else {
                   var pressure = "ปิด"
                   var humid = "ปิด"
                   var temp = "ปิด"
                   var solar = "ปิด"
                }
                
                if (current_time.diff(moment(station.time), "minutes", true) > 120) {
                  //station.time = current_time
                }

                
                
                if (station.vsupply >= 0) { 
                  var vsupply= station.vsupply
                } else {
                  var vsupply = "ปิด"
                }

              }

            tables.push(
              <tr onClick={()=>handleStationClick(station.station_id)} key={station.station_id} data-item={index} style={{borderBottom: '1px solid #cacaca'}}>
                <td  className="col-2 w-40" style={{textAlign: 'left', width: '100px'}}><h6><small><strong><a href={"./info?station_id=" + station.station_id} target="_blank">{station.station_id}.{station.name}</a></strong></small></h6></td>
              <td style={{whiteSpace: 'nowrap'}}><h6><small>{moment(station.time).format('DD-MM-YYYY HH:mm')}</small></h6></td> 
              <td ><h6><small>{station.rain15m === null ? "-" : station.rain15m}</small></h6></td>
              <td ><h6><small>{station.rain1h === null ? "-" : station.rain1h}</small></h6></td>
              <td ><h6><small>{station.rain1d === null ? "-" : station.rain1d}</small></h6></td>
              <td ><h6><small>{station.rain1 === null ? "-" : station.rain1}</small></h6></td>
              <td ><h6><small>{station.rain15m != null ? temp : "-"}</small></h6></td>
              <td ><h6><small>{station.rain15m != null ? humid : "-"}</small></h6></td>
              
              <td ><h6><small>{station.rain15m != null ? solar : "-"}</small></h6></td>
              <td ><h6><small>{station.rain15m != null ? vsupply : "-"}</small></h6></td>
              <td><h6><small>{current_time.diff(moment(station.time), "minutes", true) < 720 ? <span style={{color: 'Green'}}>ปกติ</span> : <span style={{color: 'Red'}}>ล่าช้า</span>}</small></h6></td> </tr>)
            }
         )
        }        
        }
        )
      
  
  } 
    //console.log("indexOpen:", indexOpen)
    //console.log("TEST")
        //.then(data => console.log({data}));
    //console.log(InfoWindowMap)
    //items = <InfoWindowMap />
    //console.log(items)
    //items2.push (<InfoWindowMap key="1" index="1" lat={16.2224} lng={100.475} label="Kyne123456" />)
    //items2.push (<InfoWindowMap2 key ="2" index="132" lat={16.2224} lng={101.475} label="Kyne2" />)
    console.log("hidelegen: ", HideLegend)
  return (
    <div className="row mt-5">
      
      
      <div className="col-6 mx-0 bg-white rounded shadow "> 
        {HideLegend ?
          <div  style={{
                  position: 'absolute',
                  bottom: 15,
                  left: 30,
                  height: '50px',
                  width: '100px',
                  zIndex: 1000,
                  backgroundColor: 'white',
                  opacity: 0.8
              }}> 
              <div  >
                  <p style={{ textAlign: "Left", margin: '5%', color: "Black" }}>

                  <h6 class="card-title"><strong><small>ปริมาณน้ำฝน</small> <a onClick={()=>handleLegendClick(false)} ><span  style={{color: "Red"}}>+ </span></a></strong></h6>
                  </p>
              </div>
            </div>
         : 
         <div  style={{
          position: 'absolute',
          bottom: 15,
          left: 30,
          height: '180px',
          width: '130px',
          zIndex: 1000,
          backgroundColor: 'white',
          opacity: 0.8
      }}>
          <div  >
          <p style={{ textAlign: "Left", margin: '5%', color: "Black" }}>

          <h6 class="card-title"><strong><small>ปริมาณน้ำฝน</small> <a onClick={()=>handleLegendClick(true)} ><span  style={{color: "Red"}}>- </span></a></strong> </h6>
      
          <h6 class="card-subtitle mb-2 text-muted"><small><p><img src={ require('../img/s-white.png') }/>&nbsp;&nbsp;ข้อมูลล่าช้า</p></small></h6>
          <h6 class="card-subtitle mb-2 text-muted"><small><p><img src={ require('../img/s-off.png') }/>&nbsp;&nbsp;0.0-10.0 มม.</p></small></h6>
          <h6 class="card-subtitle mb-2 text-muted"><small><p><img src={ require('../img/s-green.png') }/>&nbsp;&nbsp;10.1-35.0&nbsp;มม.</p></small></h6>
          <h6 class="card-subtitle mb-2 text-muted"><small><p><img src={ require('../img/s-orange.png') }/>&nbsp;&nbsp;35.1-90 มม.</p></small></h6>
          <h6 class="card-subtitle mb-2 text-muted"><small><p><img src={ require('../img/s-danger.png') }/>&nbsp;&nbsp;มากกว่า 90 มม.&nbsp;&nbsp;&nbsp;</p></small></h6>
          
          </p>
          </div>
      </div>
         }
       
        <LoadScript
          googleMapsApiKey="AIzaSyCeX15p-yT0MnHGKJBhdYxw6p1y-HLMYlY"
          
        >
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={6}
            options={{
              fullscreenControl: true,
              disableDefaultUI: true
            }}
          >
        
          {items}
          
          </GoogleMap>
        </LoadScript>
      </div>
      <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
      <h6 class="modal-title">ข้อมูลกราฟของสถานี</h6>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
      <div className="row"> 
      <div className="col">
      <ul className="nav justify-content-center">
              
                  
                    <li className="nav-item dropdown mx-0">
                    <h6><small>
                                      <a className="nav-link dropdown-toggle"  data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">{provinceClick}</a>
                                     
                                      {unique &&
                                        <div className="dropdown-menu">
                                          {unique.map((province, index) =>
                                            <a className="dropdown-item" onClick={()=>handleProvinceChange(province)} key={index} data-value={province} href="#">  <h6><small>{province}</small></h6></a>
                                            
                                          )}
                                        </div>   
                                      } 
                                      </small></h6>
                                  </li>
                    <li className="nav-item dropdown mx-auto">
                    <h6><small>
                                    <a className="nav-link dropdown-toggle"  data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">{stationClick}</a>
                         
                                      {obj &&
                                        <div className="dropdown-menu">
                                          {obj.filter(station => station.province === provinceClick).map((station, index) =>
                                            <a className="dropdown-item" onClick={()=>handleStationChange(station.name, station.station_id)} key={station.station_id} data-value={station.name} data-item={station.station_id} href="#"><h6><small>{station.name}</small></h6></a>
                                            
                                          )}
                                        </div>   
                                      }
                    </small></h6>
                    </li>

                    <li className="nav-item dropdown mx-auto">
                    <h6><small>
                                    <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">{sensorName}</a>
                                      <div className="dropdown-menu">
                                      <a className="dropdown-item" onClick={()=>handleSensorChange("อุณหภูมิ", "60", 'temp')} href="#"><h6><small>อุณหภูมิ</small></h6></a>
                                        <div className="dropdown-divider"></div>
                                        <a className="dropdown-item" onClick={()=>handleSensorChange("ฝนรายวัน", "1440", 'rain')} href="#"><h6><small>ฝนรายวัน</small></h6></a>
                                        <div className="dropdown-divider"></div>
                                        <a className="dropdown-item" onClick={()=>handleSensorChange("ฝนราย ชม.", "60", 'rain')} href="#"><h6><small>ฝนราย ชม.</small></h6></a>
                                        <div className="dropdown-divider"></div>
                                       
                                      </div>
                                      </small></h6>
                    </li> 
                                      
               
                  
              </ul>  
              </div>
              
              
              </div>
              <div className="row">
              <div className="col">
             {load ? 
                <div style={{width: '100%',height: '200px'}}>
                   {sensorType !== "rain" ? 
                   <LineGraph data={StationView} title={sensorName} color="#6F9BCC" logtype={sensorType} graphtype={"line"}/>
                    : <BarGraph data={StationView} title={sensorName} color="#6F9BCC" logtype={sensorType} graphtype={"line"}/>
             }
                   </div>
               : ""}
              </div>
              </div>


      </div>
    </div>
  </div>
</div>
      <div className="col-6 mx-auto bg-white rounded shadow ml-0 " > 
      
                                            <div className="row mt-2">
                                            
                                                
                                            <div className="custom-scrollbar-view ">
                                                    <table className="tableFixHead"  style={{borderBottom: '1px solid #cacaca'}}>
                                                      <thead  >
                                                      <tr >
                                                              
                                                              <th onClick={()=>handleSortTypeClick("id")} scope="col" className="col-2 w-40" style={{textAlign: 'left'}}><h6><small>
                                                                {SortType === "id" ? <span style={{color: "Red"}}><strong>&#8673;</strong></span> 
                                                                : <span style={{color: "grey"}}>&#8673;</span>} สถานี<br/><strong>Name</strong></small></h6></th>
                                                              <th scope="col" style={{whiteSpace: 'nowrap'}}><h6><small>เวลา<br/><strong>Time</strong></small></h6></th>
                                                              <th scope="col" ><h6><small>ฝน 15 นาที<br/><strong>(มม.)</strong></small></h6></th>
                                                              <th scope="col" ><h6><small>ฝน ชั่วโมงนี้<br/><strong>(มม.)</strong></small></h6></th>
                                                              <th onClick={()=>handleSortTypeClick("rain")} scope="col" ><h6><small>
                                                                {SortType === "rain" ? <span style={{color: "Red"}}><strong>&#8673;</strong></span> 
                                                                : <span style={{color: "grey"}}>&#8673;</span>} ฝน วันนี้<br/><strong>(มม.)</strong></small></h6></th>
                                                              <th scope="col" ><h6><small>ฝนสะสม รายปี<br/><strong>(มม.)</strong></small></h6></th>
                                                              <th scope="col" ><h6><small>อุณหภูมิ<br/><strong>&#8451;</strong></small></h6></th>
                                                              <th scope="col" ><h6><small>ความชื้น<br/><strong>%RH</strong></small></h6></th>
                                                              
                                                              <th scope="col" ><h6><small>พลังงานแสง<br/><strong>W/m2</strong></small></h6></th>
                                                              <th scope="col" ><h6><small>ไฟฟ้า<br/><strong>V</strong></small></h6></th>
                                                              <th scope="col" ><h6><small>สถานะ<br/><strong>&nbsp;</strong></small></h6></th>
                                                              
                                                            </tr>
                                                          </thead>
                                                        <tbody style={{borderBottom: '1px solid #cacaca'}}>
                                                          
                                                          {tables}                                                             
                                                                 
                                                        </tbody>
                                                             
                                                    </table>
                                                </div>    
                                                              
                                               
                                             </div>
                                          
                                        </div>
        
      
    </div>
  )
}
 
export default React.memo(MyComponent)