import React, { useState, Fragment } from 'react'
import { GoogleMap, LoadScript} from '@react-google-maps/api';
import axios from 'axios';
import InfoWindowMap from './infobox'
import moment from 'moment'
//import InfoWindowMap2 from './infobox'
 
const containerStyle = {
  width: '100%',
  height: '100%',
  position: "relative",
  margin: '3'
};
 
const center = {
    lat: 16.2224,
    lng: 100.475
};

function Get_Durations(Input_Time) {
  
  let days = (Input_Time / 1440 >> 0) 
  let tmp = Input_Time % 1440
   
  let hours = (tmp / 60 >>0) 
  tmp = tmp % 60
  let mytime = "" 

  if (days > 0) { mytime = days + "d " }
  if (hours > 0) { mytime = mytime + hours + "h " }
  mytime = mytime + tmp + "m"
  return mytime
} 

function MyComponent({Province, obj, obj2, indexOpen, resetClick}) {

  let items = [] ;
  const [map, setMap] = React.useState(null)
  const [indexClick, setData] = useState(null);
  let current_time = moment(Date())
  const handleReset = React.useCallback(function callback() {
    setData(null)
  }, [])


  {obj &&
    obj.map((station, index) => {
      
      items.push(
        <InfoWindowMap 
        key={station.station_id} 
        index={index} 
        lat={station.latitude} lng={station.longtitude} 
        rain={station.rain}
        label={station.name} 
        last={current_time.diff(moment(station.time), "minutes", true)}
        address={station.address}
        indexopen={indexClick}
        handleReset={handleReset}
        />
         
        )
      
    })}

    
    const handleStationClick = React.useCallback(function callback(index) {
      setData(index)
      console.info('index:', index)
      console.info("indexClick", indexClick)
    }, [])
    //console.log("Province: ", Province) ;
    //console.log("obj:", obj)
    //console.log("indexOpen:", indexOpen)
    //console.log(items)
        //.then(data => console.log({data}));
    //console.log(InfoWindowMap)
    //items = <InfoWindowMap />
    //console.log(items)
    //items2.push (<InfoWindowMap key="1" index="1" lat={16.2224} lng={100.475} label="Kyne123456" />)
    //items2.push (<InfoWindowMap2 key ="2" index="132" lat={16.2224} lng={101.475} label="Kyne2" />)
  return (
    <div className="row">
      <div className="col-lg-4 mx-auto bg-white rounded shadow ml-0"> 
                                            
                                            <div className="row mt-2">
                                                <div className="table-responsive">
                                                    <table className="table table-fixed">
                                                        <thead>
                                                          <tr>
                                                              <th scope="col" className="col-1"><h6><small>ที่<br/>#</small></h6></th>
                                                              <th scope="col" className="col-4 "><h6><small><strong>ชื่อสถานี<br/>Name</strong></small></h6></th>
                                                              <th scope="col" className="col-2"><h6><small><strong>ฝน<br/>mm</strong></small></h6></th>
                                                              <th scope="col" className="col-2"><h6><small><strong>อุณหภูมิ<br/>&#8451;</strong></small></h6></th>
                                                              <th scope="col" className="col-2"><h6><small><strong>ล่าสุด<br/>Time</strong></small></h6></th>
                                                            </tr>
                                                          </thead>
                                                    </table>
                                  
                                                    <div className="custom-scrollbar-css">
                                                    <table className="table table-fixed">
                                                        <tbody>
                                                           {obj &&
                                                              <Fragment>
                                                                {obj.map((station, index) =>
                                                                  <tr onClick={()=>handleStationClick(index)} key={index} data-item={index}>
                                                                                      
                                                                      <th scope="row" className="col-1"><h6><small>{station.station_id}</small></h6></th>
                                                                      <td className="col-4"><h6><small><strong><a href={"./info?station_id=" + station.station_id} target="_blank">{station.name}</a></strong></small></h6></td>
                                                                      <td className="col-2"><h6><small>{station.rain}</small></h6></td>
                                                                      <td className="col-2"><h6><small>{station.temp}</small></h6></td>
                                                                      <td className="col-2"><h6><small>{Get_Durations(current_time.diff(moment(station.time), "minutes"))}</small></h6></td>            
                                                                  </tr>
                                                                 )}
                                                              </Fragment>
                                                           }  
                                                                 
                                                        </tbody>
                                                             
                                                    </table>
                                                    </div>
                                                              
                                               </div>
                                             </div>
                                          
                                        </div>
      
      <div className="col-lg-4 mx-auto bg-white rounded shadow"> 
        <LoadScript
          googleMapsApiKey="AIzaSyCeX15p-yT0MnHGKJBhdYxw6p1y-HLMYlY"
        >
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={6}
            options={{
            
              disableDefaultUI: true
            }}
          >
        
          {items}
          { /* Child components, such as markers, info windows, etc. */ }
          <></>
          </GoogleMap>
        </LoadScript>
      </div>
                        
      <div className="col-lg-4 mx-auto bg-white rounded shadow ml-0"> 
                                            
          <div className="row mt-2">
              <div className="table-responsive">
                  <table className="table table-fixed">
                      <thead>
                        <tr>
                            
                            <th scope="col" className="col-1"><h6><small>ที่<br/>#</small></h6></th>
                            <th scope="col" className="col-4 "><h6><small><strong>ชื่อสถานี<br/>Name</strong></small></h6></th>
                            <th scope="col" className="col-2"><h6><small><strong>ฝน<br/>mm</strong></small></h6></th>
                            <th scope="col" className="col-2"><h6><small><strong>อุณหภูมิ<br/>&#8451;</strong></small></h6></th>
                             <th scope="col" className="col-2"><h6><small><strong>ล่าสุด<br/>Time</strong></small></h6></th>
                          </tr>
                        </thead>
                  </table>

                  <div className="custom-scrollbar-css">
                  <table className="table table-fixed">
                      <tbody>
                         {obj2 &&
                            <Fragment>
                              {obj2.map((station, index) =>
                                <tr onClick={()=>handleStationClick(index)} key={index} data-item={index}>
                                                    
                                                    <th scope="row" className="col-1"><h6><small>{station.station_id}</small></h6></th>
                                                                      <td className="col-4"><h6><small><strong><a href={"./info?station_id=" + station.station_id} target="_blank">{station.name}</a></strong></small></h6></td>
                                                                      <td className="col-2"><h6><small>{station.rain}</small></h6></td>
                                                                      <td className="col-2"><h6><small>{station.temp}</small></h6></td>
                                                                      <td className="col-2"><h6><small>{ Get_Durations(current_time.diff(moment(station.time), "minutes")) }</small></h6></td>
                                                
                                </tr>
                               )}
                            </Fragment>
                         }  
                               
                      </tbody>
                           
                  </table>
                  </div>
                            
             </div>
           </div>
        
      </div>
    </div>
  )
}
 
export default React.memo(MyComponent)