import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import MapContainer from '../_googlemaps/map_public';
import { stationActions } from '../_actions';


import moment from 'moment'



class PublicDashboard extends React.Component {

    constructor() {
        super();

        this.state = {
            province: 'ทั้งหมด',
            station: '',
            delay: false,
            active: true,
            rain: false,
            all_station: []
        };
         
       
        this.handleLogOut= this.handleLogOut.bind(this);
    }

    handleLogOut(event) {
        //console.log("Logout Clicked!");
        localStorage.removeItem('user');
        //window.location(true);
        window.location.href = '/';
      }
        
     

    componentDidMount() {
        //this.props.getStationInfo();

        const requestOptions = {
            method: 'GET',
            //headers: authHeader()
        };
    
        fetch(`https://hydromet.rid.go.th/api/queryallstation`, requestOptions).then(response => response.json())
      .then(data => this.setState({ all_station: data }));
    }

    render() {
        const { user, stations } = this.props;
        //console.log("stations = " + stations)
        let selected_province = this.state.province
        let unique = []
        //const {user} = this.props 

        const {all_station} = this.state.all_station
        /*
        var x = 90
        x = (((( ( (x-1) - ((x-1) % 10) ) ) / 10) ) % 7) + 1
        console.log("test =", x )
        */

        var items = null ;
        var obj = []
        var obj2 = []
        
        let active_count = 0
        let total_count = 103
        let delay_count = 0
        let rain_count = 0
        let current_time = moment(Date()) 
        /*
        if (stations.items) { 
            items = JSON.stringify(stations.items)
            obj = JSON.parse(items)
            active_count = obj.length
            console.log("active count:", active_count)
        }*/

        if (this.state.all_station) { 
            items = JSON.stringify(this.state.all_station)
            obj = JSON.parse(items)
            //obj = obj.sort(function(a, b){return b.rain1d - a.rain1d})
            //active_count = obj.length
            //console.log("active count:", active_count)
        }

        //console.log("all_station:", this.state.all_station)
        //console.log("stations:", all_station)

        if (obj) {
            /*
            if (this.state.active) {
                obj2 =  obj.filter(station => current_time.diff(moment(station.time), "minutes", true) < 720)
            } else if (this.state.delay) {
                obj2 =  obj.filter(station => current_time.diff(moment(station.time), "minutes", true) >= 720)
            } else if (this.state.rain) {
                obj2 =  obj.filter(station => station.rain > 0)
            }
            else {
                obj2 = obj
            }
            */
            unique = [...new Set(obj.map(item => item.province))];
            //tmp_obj = obj.filter(station => current_time.diff(moment(station.time), "minutes", true) > 120)
            //rain_count = obj.filter(station => station.rain > 40).length
            //rain_obj = obj.filter(station => station.rain > 0)
            //delay_count = obj.filter(station => current_time.diff(moment(station.time), "minutes", true) > 720).length
            //if (selected_province != "ทั้งหมด") {
            //    obj = obj.filter(station => station.province === selected_province)
            //}
        }
        let test_time = null
        
        test_time = current_time.diff(moment("2020-09-03 09:39:04+07"), "minutes", true);
        

        

        //console.log("user:", user)

        return (
            <Fragment> 

            <nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-primary">
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
                    <a class="navbar-brand" href="#">Telemetry Report</a>
                    
                        {user &&
                            <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
                            <li class="nav-item">
                            <a class="nav-link active" href="./dashboard">หน้าหลัก</a>
                        </li>
                            {user.admin === 1 &&
                                    <li class="nav-item dropdown">
                                    <a class="nav-link active dropdown-toggle" data-toggle="dropdown" href="#">จัดการ</a>
                                    <div class="dropdown-menu">
                                        <a class="dropdown-item" href="./edit">ข้อมูลสถานี</a>
                                        <a class="dropdown-item" href="./edituser">ผู้ใช้งาน</a>
                                    </div>
                                    </li>
                            }
                        </ul>
                        }
                    
    
                </div>
                <div >
                    <ul className="nav nav-pills mr-4">
                        
                       
                                {user ?
                                     <div className="btn-group dropdown">
                                     <button type="button" className="btn btn-primary dropdown-toggle" data-toggle="dropdown">
                                     <img className="img-circle" height="30px" src={require('../img/ico_usr.png')}></img>
                                         {user.username}
                                         </button>
                                    
                                             <div className="dropdown-menu">
                                               <a className="dropdown-item" key={0} onClick={this.handleLogOut} data-value="ทั้งหมด" href="#">Logout</a>
                                              
                                             </div>   
                                           
                                 </div>
                                    
                                : <li className="nav-item">
                                        <a className="nav-link active" href="./dashboard">เข้าสู่ระบบ</a>
                                    </li>
                                }
                            
                           
                    </ul>
                </div>
            </nav>

            <div className="container pt-4">

            </div>
            
           

            <div class="container-fluid">
           
                < MapContainer Province={selected_province} obj={obj} unique={unique} />
                </div>             
            

            <div className="container pt-5">
            <div className="row pb-0">

            <div className="col-lg-14 mx-0 bg-white rounded shadow">

              
                    
               
        
                         
               
                          
            </div>


            </div>
            </div>
            </Fragment> 
        );

        
    }
}

function mapState(state) {
    const { stations, authentication } = state;
    const { user } = authentication;
    return { user, stations };
}

const actionCreators = {
    getStationInfo: stationActions.getStationInfo
}

const connectedDashboard = connect(mapState, actionCreators)(PublicDashboard);
export { connectedDashboard as PublicDashboard };