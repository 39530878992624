import { stationConstants } from '../_constants';

export function stations(state = {}, action) {
  switch (action.type) {
    case stationConstants.INFO_REQUEST:
      return {
        loading: true
      };
    case stationConstants.INFO_SUCCESS:
      return {
        items: action.stations
      };
    case stationConstants.INFO_FAILURE:
      return { 
        error: action.error
      };
      case stationConstants.ID_REQUEST:
        return {
          loading: true
        };
      case stationConstants.ID_SUCCESS:
        return {
          items: action.station
        };
      case stationConstants.ID_FAILURE:
        return { 
          error: action.error
        };
    default:
      return state
  }
}