import React from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { history } from '../_helpers';
import { alertActions } from '../_actions';
import { PrivateRoute } from '../_components';

import { LoginPage } from '../LoginPage';


//Code Init
import { PublicDashboard } from '../HomePage/public_dashboard';
import { DashBoard } from '../HomePage/Dashboard';
import { StationInfo } from '../HomePage/StationInfo';
import { StationEdit } from '../HomePage/edit_station';
import { UserEdit } from '../HomePage/edit_user';
import Container from 'react-bootstrap/Container'

//Updated 29-06-2021

//Updated 29-06-2021 Updated River Basin
import { BasinInfo } from '../HomePage/BasinInfo';

class App extends React.Component {
    constructor(props) {
        super(props);

        history.listen((location, action) => {
            // clear alert on location change
            this.props.clearAlerts();
        });
    }

    render() {
        const { alert } = this.props;
        return (
            
         <Container fluid>   
            
                    
                    
                    

                    
   
                                <Router history={history}>
                                <Switch>
                                    <Route exact path="/" component={PublicDashboard} />
                                    <Route path="/login" component={LoginPage} />
                                    <Route path="/basininfo" component={BasinInfo} />
                                    <PrivateRoute path="/dashboard" component={PublicDashboard} />
                                    <PrivateRoute path="/info" component={StationInfo} />
                                    <PrivateRoute path="/edit" component={StationEdit} />
                                    <PrivateRoute path="/edituser" component={UserEdit} />
                                    <Redirect from="*" to="/" />
                                </Switch>
                            </Router>
                   
                        
                   
            
        </Container>

        );
    }
}

function mapState(state) {
    const { alert } = state;
    return { alert };
}

const actionCreators = {
    clearAlerts: alertActions.clear
};

const connectedApp = connect(mapState, actionCreators)(App);
export { connectedApp as App };