import { stationConstants } from '../_constants';
import { stationService } from '../_services';
import { alertActions } from './';
import { history } from '../_helpers';

export const stationActions = {
    getStationInfo,
    getStationInfobyId
};

function getStationInfo() {
    return dispatch => {
        dispatch(request());

        stationService.getInfo()
        .then(
            stations => dispatch(success(stations)),
            error => dispatch(failure(error))
        );
    };

    function request() { return { type: stationConstants.INFO_REQUEST } }
    function success(stations) { return { type: stationConstants.INFO_SUCCESS, stations } }
    function failure(error) { return { type: stationConstants.INFO_FAILURE, error } }
}

function getStationInfobyId(id) {
    return dispatch => {
        dispatch(request(id));

        stationService.getInfobyId(id)
        .then(
            station => dispatch(success(station)),
            error => dispatch(failure(error))
        );
    };

    function request(id) { return { type: stationConstants.ID_REQUEST, id } }
    function success(station) { return { type: stationConstants.ID_SUCCESS, station } }
    function failure(id, error) { return { type: stationConstants.ID_FAILURE, id, error } }
}