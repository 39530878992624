import React, {Component, Fragment} from 'react';
import { Marker, InfoWindow, Circle } from "@react-google-maps/api";

import marker_active from '../img/m-active-sm.png';
//import axios from 'axios';

const options = {
    strokeColor: '#31e940',
    strokeOpacity: 0.8,
    strokeWeight: 1,
    fillColor: '#31e940',
    fillOpacity: 0.4,
    clickable: true,
    draggable: false,
    editable: false,
    visible: true,
    radius: 10000,
    zIndex: 1
  }
  const shapes = {
    coords: [1, 1, 1, 20, 18, 20, 18, 1],
    type: 'poly'
  }

  var icon = {
    path: "M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0",
    //path: "M 0 0 H 40 V 40 H 40 L 10",
    fillColor: '#FF00FF',
    fillOpacity: 0,
    //anchor: new google.maps.Point(0,0),
    strokeWeight: 0,
    scale: 1
}

class InfoWindowMap extends Component {

    constructor(props){
        super(props);

        this.state = {
            isOpen: false,
        }

    }

    componentDidMount() {
        // Simple GET request using fetch
            //console.log("DidMount");
            //.then(data => console.log({data}));
            
      }


    handleToggleOpen = () => {
        //console.log("marker click")
        this.setState({
            isOpen: true
        });
    }

    handleToggleClose = () => {
        //console.log("infobox click")
        this.setState({
            isOpen: false
        });
        this.props.handleReset() ;
         
    }


  render() {
    //console.log(this.props)
    let popup = false
    //console.log("indexopen:", this.props.indexopen)
    //console.log("index:", this.props.index)
    if (this.props.indexopen == this.props.index) {
        popup = true
    }
    return (
        <Fragment>
        <Marker
        key={this.props.index}
        position={{ lat: this.props.lat, lng: this.props.lng}}
        //icon={"http://maps.google.com/mapfiles/kml/paddle/grn-blank.png"}
        //icon={icon}
        //color="blue"
        icon={marker_active}
        //label={this.props.label}
        title={this.props.label} 
        //shape={shapes}
        onClick={() => this.handleToggleOpen()}
    >
    </Marker>
    {
        (this.state.isOpen || popup) &&
     <InfoWindow onCloseClick={() => this.handleToggleClose()} position={{ lat: this.props.lat, lng: this.props.lng}}>
         
        <span>
        <span style={{ color: 'red' , fontWeight: 'bold'}}>{this.props.label}<br/></span>
        <span style={{ color: 'red' , fontWeight: 'bold'}}>สถานีที่ {this.props.index}<br/></span>
             {this.props.address}
        <br/>
        <a href={"https://maps.google.com/?saddr=My%20Location&daddr=" + this.props.lat + "," + this.props.lng}>เส้นทาง</a>
        </span>
     </InfoWindow>
    }

    <Circle
     
      center={{ lat: this.props.lat, lng: this.props.lng}}
      // required
      //options={options}
      onClick={() => this.handleToggleOpen()}
    />
</Fragment>
    
        )
  }
}

export default InfoWindowMap;