import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { stationActions } from '../_actions';
import MapContainer from '../_googlemaps/mapinfo';
import queryString from 'query-string';

import Chart  from "chart.js";
//import classes from "./LineGraph.module.css";

import Moment from 'react-moment';

import moment from 'moment'

import { saveAs } from 'file-saver';


export class BarGraph extends React.Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  componentDidUpdate() {
    var datax = []
    //var graph_type = 'line'
    //console.log("typelog:", this.props.logtype)
    if(this.props.logtype === 'humid') {
      datax = this.props.data.map(d => d.humid)
    } else if (this.props.logtype === 'solar'){
      datax = this.props.data.map(d => d.solar)
    } else if (this.props.logtype === 'rain'){
      //graph_type = 'bar'
      datax = this.props.data.map(d => d.rain)
    } else if (this.props.logtype === 'wind'){
      datax = this.props.data.map(d => d.wind_speed)
    } else if (this.props.logtype === 'gatecounter'){
      datax = this.props.data.map(d => d.gatecounter)
    } else if (this.props.logtype === 'vsupply'){
      datax = this.props.data.map(d => d.vsupply)
    }
    else {
      datax = this.props.data.map(d => d.temp)
    }
    this.myChart.data.labels = this.props.data.map(d => d.hours);
    this.myChart.type = 'bar'
    //this.myChart.data.datasets[0].data = this.props.data.map(d => d.temp);
    this.myChart.data.datasets[0].data = datax
    this.myChart.data.datasets[0].label = this.props.title
    this.myChart.update();
  }

  get_Data(logtype){
    var datax = []
    if(logtype === 'humid') {
      datax = this.props.data.map(d => d.humid)
    } else if (logtype === 'solar'){
      datax = this.props.data.map(d => d.solar)
    } else if (logtype === 'rain'){
      //graph_type = 'bar'
      datax = this.props.data.map(d => d.rain)
    } else if (logtype === 'wind'){
      datax = this.props.data.map(d => d.wind_speed)
    } else if (logtype === 'gatecounter'){
      datax = this.props.data.map(d => d.gatecounter)
    } else if (logtype === 'vsupply'){
      datax = this.props.data.map(d => d.vsupply)
    }
    else {
      datax = this.props.data.map(d => d.temp)
    }

    return datax 
  }

  componentDidMount() {
    //const datax = this.props.data.map(d => d.temp)
    const datax = this.get_Data(this.props.logtype)
    this.myChart = new Chart(this.canvasRef.current, {
      type: 'bar',
      options: {
              maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              type: 'time',
              time: {
                //parser: 'MM-DD-YYYYTHH:mm',
                tooltipFormat: 'll HH:mm',
                unit: 'hour',
                unitStepSize: 3,
                displayFormats: {
                  'hour': 'HH:mm'
                },
              },
                ticks: {
                    major: {
                       enabled: true, // <-- This is the key line
                       fontStyle: 'bold', //You can also style these values differently
                       fontSize: 14 //You can also style these values differently
                    },
                }
            }],
          yAxes: [
            {
              ticks: {
                min: 0,
                stepSize: 10,
              }
            }
          ]
        }
      },
      data: {
        labels: this.props.data.map(d => d.hours),
        datasets: [{
          label: this.props.title,
          //data: this.props.data.map(d => d.temp),
          data: datax,
          fill: 'none',
          backgroundColor: this.props.color,
          pointRadius: 2,
          borderColor: this.props.color,
          borderWidth: 1,
          lineTension: 0
        }]
      }
    });
  }

  render() {
    
    return (
      
        <canvas ref={this.canvasRef} />
      
    )
    }
}

export class LineGraph extends React.Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }
  
  get_Data(logtype){
    var datax = []
    if(logtype === 'humid') {
      datax = this.props.data.map(d => d.humid)
    } else if (logtype === 'solar'){
      datax = this.props.data.map(d => d.solar)
    } else if (logtype === 'rain'){
      //graph_type = 'bar'
      datax = this.props.data.map(d => d.rain)
    } else if (logtype === 'wind'){
      datax = this.props.data.map(d => d.wind_speed)
    } else if (logtype === 'gatecounter'){
      datax = this.props.data.map(d => d.gatecounter)
    } else if (logtype === 'vsupply'){
      datax = this.props.data.map(d => d.vsupply)
    }
    else {
      datax = this.props.data.map(d => d.temp)
    }

    return datax 
  }

  componentDidUpdate() {
    var datax = this.get_Data(this.props.logtype)
    //var graph_type = 'line'
    //console.log("typelog:", this.props.logtype)
   /*
    if(this.props.logtype === 'humid') {
      datax = this.props.data.map(d => d.humid)
    } else if (this.props.logtype === 'solar'){
      datax = this.props.data.map(d => d.solar)
    } else if (this.props.logtype === 'rain'){
      //graph_type = 'bar'
      datax = this.props.data.map(d => d.rain)
    } else if (this.props.logtype === 'wind'){
      datax = this.props.data.map(d => d.wind_speed)
    } else if (this.props.logtype === 'gatecounter'){
      datax = this.props.data.map(d => d.gatecounter)
    } else if (this.props.logtype === 'vsupply'){
      datax = this.props.data.map(d => d.vsupply)
    }
    else {
      datax = this.props.data.map(d => d.temp)
    }
    */
    this.myChart.data.labels = this.props.data.map(d => d.hours);
    this.myChart.type = 'bar'
    //this.myChart.data.datasets[0].data = this.props.data.map(d => d.temp);
    this.myChart.data.datasets[0].data = datax
    this.myChart.data.datasets[0].label = this.props.title
    this.myChart.update();
  }

  componentDidMount() {
    //const datax = this.props.data.map(d => d.temp)
    const datax = this.get_Data(this.props.logtype)
    this.myChart = new Chart(this.canvasRef.current, {
      type: 'line',
      options: {
              maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              type: 'time',
              time: {
                //parser: 'MM-DD-YYYYTHH:mm',
                tooltipFormat: 'll HH:mm',
                unit: 'hour',
                unitStepSize: 3,
                displayFormats: {
                  'hour': 'HH:mm'
                },
              },
                ticks: {
                    major: {
                       enabled: true, // <-- This is the key line
                       fontStyle: 'bold', //You can also style these values differently
                       fontSize: 14 //You can also style these values differently
                    },
                }
            }],
          yAxes: [
            {
              ticks: {
                min: 0,
                stepSize: 10,
              }
            }
          ]
        }
      },
      data: {
        labels: this.props.data.map(d => d.hours),
        datasets: [{
          label: this.props.title,
          //data: this.props.data.map(d => d.temp),
          data: datax,
          fill: 'none',
          backgroundColor: this.props.color,
          pointRadius: 2,
          borderColor: this.props.color,
          borderWidth: 1,
          lineTension: 0
        }]
      }
    });
  }

  render() {
    
    return (
      
        <canvas ref={this.canvasRef} />
      
    )
    }
}


function ConvertToCSV(objArray) {
  var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
  var str = '';

  for (var i = 0; i < array.length; i++) {
      var line = '';
      var indexstr = '' ;
      for (var index in array[i]) {
          if (line != '') {
            line += ','
            indexstr += ','
          }
          
          if(i==0) {
            indexstr += index 
          }

          //console.log("index :", index)
          if (index === 'hours') {
            line += moment(array[i][index]).format('DD/MM/YYYY HH:mm')
          } else {
            line += array[i][index];
          }
      }
      if (i === 0 ) {
        str = indexstr + '\r\n'
      }
      str += line + '\r\n';
  }

  return str;
}


class StationInfo extends React.Component {
    constructor() {
        super();

        this.state = {
            province: 'กรุงเทพมหานคร',
            station: 'กรุณาเลือก สถานี',
            station_id: '',
            logType: 'อุณหภูมิ',
            TypeLog: 'temp',
            Graph_Type: 'bar',
            stationdata: [],
            data: [],
            date_start: moment().subtract(1, 'days').format('YYYY-MM-DDTHH:mm'),
            date_end: moment().format('YYYY-MM-DDTHH:mm'),
            time_type: 60,
            time_type_text: '1 ชั่วโมง',
            province_obj: []
        };
         

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleStationClick = this.handleStationClick.bind(this);
        this.handlelogTypeClick = this.handlelogTypeClick.bind(this);
        this.handleDateStartChange = this.handleDateStartChange.bind(this);
        this.handleDateEndChange = this.handleDateEndChange.bind(this);
        this.handleTimeTypeClick = this.handleTimeTypeClick.bind(this);
        this.handleDownload = this.handleDownload.bind(this);
        this.getInfo = this.getInfo.bind(this);
        this.handleLogOut= this.handleLogOut.bind(this);
    }
    
    handleLogOut(event) {
      //console.log("Logout Clicked!");
      localStorage.removeItem('user');
      //window.location(true);
      window.location.href = '/';
    }

    getInfo(get_station_id) {
      if (this.state.station_id != '') {
        var station_id = this.state.station_id
      } else {
    
        var station_id = get_station_id
      }
      
      //console.log("mystation_id: ", station_id)
      var log_type = this.state.time_type
      var date_start = this.state.date_start
      var date_end = this.state.date_end
      const requestOptions = {
          method: 'POST',
          //headers: authHeader(),
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ station_id, log_type, date_start, date_end })
      };

      //console.log("requestOptions: ", requestOptions)
      
      fetch(`https://hydromet.rid.go.th/api/querylog`, requestOptions).then(response => response.json())
      .then(data => this.setState({ stationdata: data }));
    }

      handleChange(event) {
        //console.log(event.target);
        this.setState({province: event.target.dataset.value})
        this.setState({station_id: ''})
        //console.log("station_id:", this.state.station_id)
        this.setState({station: ''})
      }

      handleClick(event) {
        //console.log(event.target.dataset.value);
        this.setState({province: event.target.dataset.value})
        this.setState({station: ''})
        
      }

      handleStationClick(event) {
        //console.log(event.target.dataset.value);
        this.setState({station: event.target.dataset.value})
        this.setState({station_id: event.target.dataset.item})
      }

      handleDateStartChange(event) {
        //console.log(event.target.value);
        
        //var date_start = event.target.value + '+07'
        var date_start= moment(event.target.value).format('YYYY-MM-DDTHH:mm')
        this.setState({date_start: date_start})
        //console.log(date_start);
        //this.setState({date_start: date_start})
        
        //this.setState({date_start: event.target.value + '+07'})
        
        //this.setState({station: event.target.dataset.value})
        //this.setState({station_id: event.target.dataset.item})
      }
      handleDateEndChange(event) {
        //console.log(event.target.value);
        var date_end = moment(event.target.value).format('YYYY-MM-DDTHH:mm')
        this.setState({date_end: date_end})
        //console.log(date_end);
        //this.setState({date_end: date_end})
        
        //this.setState({date_end: event.target.value + '+07'})
        
        //this.setState({station_id: event.target.dataset.item})
      }
      handleTimeTypeClick(event) {
        //console.log(event.target.dataset.value);
        
        this.setState({time_type: event.target.dataset.value})
        this.setState({time_type_text: event.target.dataset.item})
      }

      handlelogTypeClick(event) {
        //console.log(event.target.dataset.item);
        var logtype = event.target.dataset.value
        
        if (logtype === "rain") {
          //console.log("logtype :", logtype)
          this.setState({Graph_Type: 'bar'})
          //console.log("Graph_Type:", this.state.Graph_Type)
        } else {
          this.setState({Graph_Type: 'line'})
          //console.log("Graph_Type:", this.state.Graph_Type)
        }
        //this.setState({GraphType: 'bar'})
        
        this.setState({logType: event.target.dataset.item})
        this.setState({TypeLog: event.target.dataset.value})
      }
        
      handleSubmit(event) {
        //console.log("Show Clicked:")
        this.getInfo()
        //console.log(this.state);
        event.preventDefault();
      }

      handleDownload(event) {
       
        //console.log("Downloaded:")
        if (this.state.stationdata.length > 0) {
          var StationData = this.state.stationdata
          var csvData = ConvertToCSV(StationData)
          var FileSaver = require('file-saver');
          //console.log("station :", this.state.station)
          var blob = new Blob([csvData], {type: "text/csv;charset=utf-8"});
          FileSaver.saveAs(blob, "station-" + this.state.station_id + "-export.csv");
        }

        
        //console.log("csv", csvData)
        //this.getInfo()
        //console.log(this.state);
        //event.preventDefault();
      }

    componentDidMount() {
        this.props.getStationInfo();
        let params = queryString.parse(this.props.location.search)
        //console.log("query string:", params)
        if (params) {
          this.setState({station_id: params.station_id}, () => { this.getInfo() })
          //this.getInfo(params.station_id)
        }
        let items = []
        let obj = []
        let unique = []
        if (this.props.stations.items) { 
          items = JSON.stringify(this.props.stations.items)
          obj = JSON.parse(items)
          unique = [...new Set(obj.map(item => item.province))];
          this.setState({province_obj: unique})
          //obj = obj.filter(station => station.station_id === params.station_id)
          //this.setState({station: "สถานีที่ " + obj.station_id + " " + obj.name})
      }
        
        //console.log("init province:", this.state.province_obj)
    }

    render() {
        const { user, stations} = this.props
        let selected_province = this.state.province
        let selected_station = this.state.station
        //let init_obj = null 
        var items = null 
        var obj = []
        var selected_obj = []
        let unique = []
        var stationdata = this.state.stationdata
        if (stations.items) { 
            items = JSON.stringify(stations.items)
            obj = JSON.parse(items)
            selected_obj = JSON.parse(items)
        }
        
        let site_img = 'Station' + this.state.station_id.padStart(3,"0") + ".jpg"

        try {
          var src = require('../sites/' + site_img) 
        }catch(err){
          var src=  require('../img/sites.jpg')
          //Do whatever you want when the image failed to load here
        }

        if (selected_obj) {

          //if (this.state.station_id != "") {
            selected_obj = selected_obj.filter(station => station.station_id === this.state.station_id)
            {selected_obj.map((station, index) =>
              { 
                selected_station = "สถานีที่ " + station.station_id + " " + station.name
                selected_province = station.province
              }
            
            )}
          //} 
        }

        if (obj) {
          unique = [...new Set(obj.map(item => item.province))];
          //console.log(unique);
          obj = obj.filter(station => station.province === selected_province)
        }
        
        console.log('now() - 1day:', moment().subtract(1, 'days').format('MM/DD/YYYY HH:mm'))
        //let filteredStations = stations.items.filter(station => station.id === 5);
        //console.log(moment('2014-08-20 15:30:00').format('MM/DD/YYYY h:mm a'));
        //console.log("province = " + selected_province)
        //console.log("station_id = " + this.state.station_id)
        //console.log("TypeLog: ", this.state.TypeLog)
        //console.log("LogType: ", this.state.logType)
        //console.log("Selected station_id:", selected_obj)
        //console.log("init_object:", init_obj)
        //console.log("station_data = ", this.state.stationdata)
        //console.log("Data: ", this.state.data)
        //console.log("stations = " + obj)
        //const dateToFormat = new Date('1976-04-19T12:59-0500');
            
       
        return (

          <Fragment> 

            <nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-primary">
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
                    <a className="navbar-brand" href="#">Telemetry Report</a>
                    <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
                        <li className="nav-item">
                            <a className="nav-link active" href="./">หน้าหลัก</a>
                        </li>
                        <li className="nav-item dropdown">
                        <a className="nav-link active dropdown-toggle" data-toggle="dropdown" href="#">จัดการ</a>
                            <div className="dropdown-menu">
                                <a className="dropdown-item" href="./edit">ข้อมูลสถานี</a>
                                <a className="dropdown-item" href="./edituser">ผู้ใช้งาน</a>
                            </div>
                        </li>
                    </ul>
    
                </div>
                <div >
                                <div className="btn-group dropdown">
                                     <button type="button" className="btn btn-primary dropdown-toggle" data-toggle="dropdown">
                                     <img className="img-circle" height="30px" src={require('../img/ico_usr.png')}></img>
                                         {user.username}
                                         </button>
                                    
                                             <div className="dropdown-menu">
                                               <a className="dropdown-item" key={0} onClick={this.handleLogOut} data-value="ทั้งหมด" href="#">Logout</a>
                                              
                                             </div>   
                                           
                                 </div>
                </div>
            </nav>
          
          <div className="container pt-3">
          </div>
          
          <div className="container pt-5">
            <div className="row pb-8">

            <div className="col-lg-12 mx-auto">
               
                <div className="card ">
                        
                <div className="card-body p-2">
                
                    <div className="row">
                
                        <div className="col-lg-12 mx-auto">
                
                            <ul className="nav justify-content-center">
                                <li className="nav-item dropdown">
                                  <a className="nav-link dropdown-toggle btn btn-primary" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">{selected_province}</a>
                                     
                                      {unique &&
                                        <div className="dropdown-menu">
                                          {unique.map((province, index) =>
                                            <a className="dropdown-item" key={index} onClick={this.handleChange} data-value={province} href="#">{province}</a>
                                            
                                          )}
                                        </div>   
                                      } 
                                  </li>
                                  <li className="nav-item dropdown ml-2">
                                      <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">{selected_station}</a>
                         
                                      {obj &&
                                        <div className="dropdown-menu">
                                          {obj.map((station, index) =>
                                            <a className="dropdown-item" key={station.station_id} onClick={this.handleStationClick} data-value={"สถานีที่ " + station.station_id + " " + station.name} data-item={station.station_id} href="#">สถานีที่ {station.station_id} {station.name}</a>
                                            
                                          )}
                                        </div>   
                                      }
                    
                                  </li>
                                  <li className="nav-item dropdown">
                                      <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">{this.state.logType}</a>
                                      <div className="dropdown-menu">
                                        <a className="dropdown-item" onClick={this.handlelogTypeClick} data-value="temp" data-item="อุณหภูมิ" href="#">อุณหภูมิ</a>
                                        <div className="dropdown-divider"></div>
                                        <a className="dropdown-item" onClick={this.handlelogTypeClick} data-value="humid" data-item="ความชื้น">ความชื้น</a>
                                        <div className="dropdown-divider"></div>
                                        <a className="dropdown-item" onClick={this.handlelogTypeClick} data-value="rain" data-item="ปริมาณฝน">ปริมาณฝน</a>
                                        <div className="dropdown-divider"></div>
                                        <a className="dropdown-item" onClick={this.handlelogTypeClick} data-value="wind" data-item="แรงลม">แรงลม</a>
                                        <div className="dropdown-divider"></div>
                                        <a className="dropdown-item" onClick={this.handlelogTypeClick} data-value="solar" data-item="พลังงานแสง">พลังงานแสง</a>
                                        <div className="dropdown-divider"></div>
                                        <a className="dropdown-item" onClick={this.handlelogTypeClick} data-value="gate" data-item="สถานะประตู (เปิด/ปิด)">สถานะประตู (เปิด/ปิด)</a>
                                        <div className="dropdown-divider"></div>
                                        <a className="dropdown-item" onClick={this.handlelogTypeClick} data-value="gatecounter" data-item="ตัวนับการเปิดประตู">ตัวนับการเปิดประตู</a>
                                        <div className="dropdown-divider"></div>
                                        <a className="dropdown-item" onClick={this.handlelogTypeClick} data-value="vsupply" data-item="ไฟเลี้ยง">ไฟเลี้ยง</a>
                                        <div className="dropdown-divider"></div>
                                      </div>
                                  </li> 
                    
                            </ul>            
                    
                        </div>
                    </div>

                    <div className="row mt-2">
                
                        <div className="col-lg-12 mx-auto">

                            <ul className="nav nav-pills justify-content-center">
                           
                                  <li className="nav-item mr-2">
                                    <input className="form-control " onChange={this.handleDateStartChange} value={this.state.date_start} type="datetime-local" id="example-datetime-local-input"></input>
                                  </li>
                                  <li className="nav-item ml-2">
                                    <input className="form-control" onChange={this.handleDateEndChange} value={this.state.date_end} type="datetime-local" id="example-datetime-local-input"></input>
                                  </li>
                                  <li className="nav-item dropdown">
                                      <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">{this.state.time_type_text}</a>
                                      <div className="dropdown-menu">
                                        <a className="dropdown-item" onClick={this.handleTimeTypeClick} data-value="15" data-item="15 นาที" href="#">15 นาที</a>
                                        <div className="dropdown-divider"></div>
                                        <a className="dropdown-item" onClick={this.handleTimeTypeClick} data-value="60" data-item="1 ชั่วโมง">1 ชั่วโมง</a>
                                        <div className="dropdown-divider"></div>
                                        <a className="dropdown-item" onClick={this.handleTimeTypeClick} data-value="1440" data-item="1 วัน">1 วัน</a>
                                        <div className="dropdown-divider"></div>
                                      </div>
                                  </li> 
                                  <li className="nav-item ml-2">
                                    <a className="btn btn-primary btn-sm" onClick={this.handleSubmit} href="#" tabIndex="-1" >ดึงข้อมูล</a>
                                  </li>

                                  <li className="nav-item ml-2">
                                    <a className="btn btn-success btn-sm" onClick={this.handleDownload} href="#" tabIndex="-1" >นำออกข้อมูล</a>
                                  </li>
                            </ul>

                        </div>
                    </div>

                    <div className="row">
                
                        <div className="col-lg-12 mx-0">
                            <div className="row">
                           
                                   <div className="col-lg-7 mx-auto">
                                      <div className="row">
                                          <div className="col mx-auto">
                                            <div className="card mt-2">
                                              <div className="card-body p-2">
                                                  
                                              {this.state.TypeLog !== 'rain' ? 
                                                  <LineGraph data={this.state.stationdata} title={this.state.logType} color="#3E517A" logtype={this.state.TypeLog} graphtype={this.state.Graph_Type}/>
                                                  : <BarGraph data={this.state.stationdata} title={this.state.logType} color="#3E517A" logtype={this.state.TypeLog} graphtype={this.state.Graph_Type}/>
                                                  }
                                                  
                                              </div>
                                            </div>
                                          </div>
                                      </div>
                                      <div className="row">
                                        <div className="col mx-auto">
                                          <div className="card mt-2">
                                            <div className="card-body p-2">
                                              < MapContainer Province={selected_province} obj={selected_obj} />
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                    </div>

                                   
                                   <div className="col-lg-5 mx-auto">
                                          {selected_obj &&
                                               <div className="card m-2 h-90" >
                                                  {selected_obj.map((station, index) =>
                                                      <div key={station.station_id}>
                                                       
                                                        <div className="card-body" key={station.station_id}>
                                                        <img className=" mr-auto ml-auto" style={{justifyContent: 'center'}} height="200px" src={src} alt={station.name}></img>
                                                            <h6 className="card-title"><small><strong>สถานีที่ {station.station_id}</strong></small></h6>
                                                            <h6><small><p className="card-text"> ชื่อ {station.name} </p></small></h6>
                                                            <h6><small><p className="card-text"> ที่อยู่ {station.address} </p></small></h6>
                                                            <h6><small><p className="card-text">พิกัด {station.latitude}, {station.longtitude} </p></small></h6>
                                                            <a href={"https://maps.google.com/?saddr=My%20Location&daddr=" + station.latitude + "," + station.longtitude} target="_blank" className="btn btn-info btn-sm"> <h6><small>เส้นทาง</small></h6></a>
                                                        </div>
                                                                
                                                      </div>
                                                  )}
                                                </div>
                                          }
                                          
                                   
                                        

                                        
                                   </div>
                            </div>                           

                        </div>
                    </div>
              <div className="row m-2 mx-auto">
              <div className="table-responsive">
                  <table className="table table-fixed">
                      <thead>
                        <tr>
                            <th scope="col" className="col-2"><h6><small><strong>เวลา<br/>Time</strong></small></h6></th>
                            <th scope="col" className="col-2"><h6><small><strong>ปริมาณฝน<br/>mm</strong></small></h6></th>
                            <th scope="col" className="col-2"><h6><small><strong>อุณหภูมิ<br/>&#8451;</strong></small></h6></th>
                            <th scope="col" className="col-2"><h6><small><strong>ความชื้น<br/>%RH</strong></small></h6></th>
                            <th scope="col" className="col-2"><h6><small><strong>ลม<br/>m/s</strong></small></h6></th>
                            <th scope="col" className="col-2"><h6><small><strong>พลังานแสง<br/>W/m2</strong></small></h6></th>
                          </tr>
                        </thead>
                  </table>

                  <div className="custom-scrollbar-css">
                  <table className="table table-fixed">
                      <tbody>
                               
                                {stationdata &&
                                    <Fragment>
                                        {stationdata.map((data, index) =>
                                          <tr key={data.station_id}>
                                          <th scope="row" class="col-2"><h6><small><strong>{ <Moment format="YYYY-MM-DD HH:mm" date={data.hours}/>}</strong></small></h6></th>
                                          <td className="col-2"><h6><small>{data.rain}</small></h6></td>
                                          <td className="col-2"><h6><small>{data.temp}</small></h6></td>
                                          <td className="col-2"><h6><small>{data.humid}</small></h6></td>
                                          <td className="col-2"><h6><small>{data.wind_speed}</small></h6></td>
                                          <td className="col-2"><h6><small>{data.solar}</small></h6></td>  
                                          </tr> 
                                        )}
                                     </Fragment> 
                                 }
                      </tbody>
                           
                  </table>
                  </div>
                            
             </div>
           </div>
                </div>

            </div>  
            
            </div>


            </div>
            </div>
            </Fragment> 
        );
    }
}

function mapState(state) {
    const { stations, authentication } = state;
    const { user } = authentication;
    return { user, stations };
}

const actionCreators = {
    getStationInfo: stationActions.getStationInfo
}

const connectedStationInfo = connect(mapState, actionCreators)(StationInfo);
export { connectedStationInfo as StationInfo };