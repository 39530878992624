import { authHeader } from '../_helpers';

export const stationService = {
    getInfo,
    getInfobyId
};


function getInfo() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`https://hydromet.rid.go.th/api/querydata`, requestOptions).then(handleResponse);
}

function getInfobyId(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`https://hydromet.rid.go.th/api/querystation/${id}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    
    return response.text().then(text => {
        
        const data = text && JSON.parse(text);
        //console.log("response1 = " + data) 
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                //logout();
                //location.reload(true);
                window.location(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        //console.log("response = " + text) 
        return data;
    });
}