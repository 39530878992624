import React, {Component, Fragment} from 'react';
import { Marker, InfoWindow, Circle } from "@react-google-maps/api";

import marker_active from '../img/m-active-sm.png';
//import axios from 'axios';

const options = {
    strokeColor: '#31e940',
    strokeOpacity: 0.8,
    strokeWeight: 1,
    fillColor: '#31e940',
    fillOpacity: 0.4,
    clickable: true,
    draggable: false,
    editable: false,
    visible: true,
    radius: 10000,
    zIndex: 1
  }
  const shapes = {
    coords: [1, 1, 1, 20, 18, 20, 18, 1],
    type: 'poly'
  }

  var icon = {
    path: "M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0",
    //path: "M 0 0 H 40 V 40 H 40 L 10",
    fillColor: '#FF00FF',
    fillOpacity: 0,
    //anchor: new google.maps.Point(0,0),
    strokeWeight: 0,
    scale: 1
}

class InfoWindowMap extends Component {

    constructor(props){
        super(props);

        this.state = {
            isOpen: false,
        }

    }

    componentDidMount() {
        // Simple GET request using fetch
            //console.log("DidMount");
            //.then(data => console.log({data}));
            
      }


    handleToggleOpen = () => {
        //console.log("marker click")
        this.setState({
            isOpen: true
        });
    }

    handleToggleClose = () => {
        //console.log("infobox click")
        this.setState({
            isOpen: false
        });
        this.props.handleReset() ;
         
    }


  render() {
    //console.log(this.props)
    let popup = false
    //console.log("indexopen:", this.props.indexopen)
    //console.log("index:", this.props.index)
    if (this.props.indexopen == this.props.index) {
        popup = true
    }
    var image = require('../img/s-green.png')
    let url = '../img/s-green.png'
    if (this.props.rain <= 10) {
        image = require('../img/s-green.png')
        url = '../img/s-green.png'
    }

    if (this.props.rain > 10 && this.props.rain <= 35) {
        image = require('../img/s-green.png')
        url = '../img/s-green.png'
    }

    if (this.props.rain > 35 && this.props.rain <= 90) {
        image = require('../img/s-orange.png')
        url = '../img/s-orange.png'
    } 

    if (this.props.rain > 90) {
        image = require('../img/s-danger.png')
        url = '../img/s-danger.png'
    }
    
    if(this.props.last > 720){
        image = require('../img/s-white.png')
        url = '../img/s-white.png'
    }

    var icon = {
        url: {url}, // url
        scaledSize: { height: 50, width: 50 }, // size
    };
    return (
        <Fragment>
        <Marker
        key={this.props.key}
        position={{ lat: this.props.mystation.latitude, lng: this.props.mystation.longtitude}}
        //icon={"http://maps.google.com/mapfiles/kml/paddle/grn-blank.png"}
        //icon={icon}
        //color="blue"
        //icon={marker_active}
        
        icon={image}
        //icon={icon}
        zIndex={200 - this.props.rain1d - this.props.myindex}
        //label={this.props.label}
        title={this.props.mystation.name} 
        //shape={shapes}
        onClick={() => this.handleToggleOpen()}
    >
    </Marker>
    {
        (this.state.isOpen || popup) &&
     <InfoWindow onCloseClick={() => this.handleToggleClose()} position={{ lat: this.props.mystation.latitude, lng: this.props.mystation.longtitude}}>
         
        <span>
        <span style={{ color: 'red' , fontWeight: 'bold'}}>{this.props.mystation.name}<br/></span>
        <span style={{ color: 'red' , fontWeight: 'bold'}}>สถานีที่ {this.props.mystation.station_id}<br/></span>
             {this.props.mystation.address}
        <br/><br/>
        ปริมาณน้ำฝนวันนี้ {this.props.mystation.rain1d} มม.
        <label type="button" onClick={() => this.props.mapStationClick(this.props.mystation.name,this.props.mystation.province, this.props.mystation.station_id)} class="btn btn-link btn-sm" data-toggle="modal" data-target="#exampleModalCenter" >
        <img height="16px" src={require('../img/barchart.jpg')}></img>
        </label><br/>
        <a href={"https://maps.google.com/?saddr=My%20Location&daddr=" + this.props.mystation.latitude + "," + this.props.mystation.longtitude}>เส้นทาง</a>
        
        </span>
     </InfoWindow>
    }

    <Circle
     
      center={{ lat: this.props.lat, lng: this.props.lng}}
      // required
      //options={options}
      onClick={() => this.handleToggleOpen()}
    />
</Fragment>
    
        )
  }
}

export default InfoWindowMap;