import React, { useState, Fragment } from 'react'
import { GoogleMap, LoadScript} from '@react-google-maps/api';
import axios from 'axios';
import InfoWindowMap from './infoboxstation'
//import InfoWindowMap2 from './infobox'
 
const containerStyle = {
  width: '100%',
  height: '200px',
  position: "relative",
  margin: '3'
};
 
var center = {
    lat: 16.2224,
    lng: 100.475
};

function MyComponent({Province, obj, indexOpen, resetClick}) {

  let items = [] ;
  const [map, setMap] = React.useState(null)
  const [indexClick, setData] = useState(null);

  const handleReset = React.useCallback(function callback() {
    setData(null)
  }, [])


  {obj &&
    obj.map((station, index) => {
      center = {
         lat: station.latitude,
         lng: station.longtitude
      }

      items.push(
        <InfoWindowMap 
        key={station.id} 
        index={index} 
        lat={station.latitude} lng={station.longtitude} 
        label={station.name} 
        address={station.address}
        indexopen={indexClick}
        handleReset={handleReset}
        />
         
        )
      
    })}

    
    const handleStationClick = React.useCallback(function callback(index) {
      setData(index)
      console.info('index:', index)
      console.info("indexClick", indexClick)
    }, [])

    var loadkey = Math.floor(Math.random()*(2-0+1)+0)
    var apikey = ""
    if (loadkey === 0) {
      apikey = "AIzaSyD7TdhMGNrg45iHmXpUVo84s6GxZ8vZc2w"
    } else if (loadkey === 1) {
      apikey = "AIzaSyB-_tcUPCLGjoMOXJ79NkqA-X5qPXw3u_s"
    } else {
      apikey = "AIzaSyCeX15p-yT0MnHGKJBhdYxw6p1y-HLMYlY"
    }
    //console.log("loadkey:", loadkey) 
    //console.log("Province: ", Province) ;
    //console.log("obj:", obj)
    //console.log("indexOpen:", indexOpen)
    //console.log(items)
        //.then(data => console.log({data}));
    //console.log(InfoWindowMap)
    //items = <InfoWindowMap />
    //console.log(items)
    //items2.push (<InfoWindowMap key="1" index="1" lat={16.2224} lng={100.475} label="Kyne123456" />)
    //items2.push (<InfoWindowMap2 key ="2" index="132" lat={16.2224} lng={101.475} label="Kyne2" />)
  return (

        

      
      
        <LoadScript
          //googleMapsApiKey="AIzaSyCeX15p-yT0MnHGKJBhdYxw6p1y-HLMYlY"
          //googleMapsApiKey="AIzaSyD7TdhMGNrg45iHmXpUVo84s6GxZ8vZc2w"
          googleMapsApiKey ={apikey}
        >
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={15}
            options={{
            
              disableDefaultUI: true
            }}
          >
        
          {items}
          { /* Child components, such as markers, info windows, etc. */ }
          <></>
          </GoogleMap>
        </LoadScript>
                               
                                            
   
  )
}
 
export default React.memo(MyComponent)