import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { stationActions } from '../_actions';
import MapContainer from '../_googlemaps/mapinfo';
import queryString from 'query-string';

import Chart  from "chart.js";
//import classes from "./LineGraph.module.css";

import Moment from 'react-moment';

import moment from 'moment'

import { saveAs } from 'file-saver';
import { ContactSupportOutlined } from '@material-ui/icons';


export class BarGraph extends React.Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  componentDidUpdate() {
    var datax = []
    //var graph_type = 'line'
    //console.log("typelog:", this.props.logtype)
    /*
    if(this.props.logtype === 'humid') {
      datax = this.props.data.map(d => d.humid)
    } else if (this.props.logtype === 'solar'){
      datax = this.props.data.map(d => d.solar)
    } else if (this.props.logtype === 'rain'){
      //graph_type = 'bar'
      datax = this.props.data.map(d => d.rain)
    } else if (this.props.logtype === 'wind'){
      datax = this.props.data.map(d => d.wind_speed)
    } else if (this.props.logtype === 'gatecounter'){
      datax = this.props.data.map(d => d.gatecounter)
    } else if (this.props.logtype === 'vsupply'){
      datax = this.props.data.map(d => d.vsupply)
    }
    else {
      datax = this.props.data.map(d => d.temp)
    }
    */
    this.myChart.data.labels = this.props.data.map(d => d.hours);
    this.myChart.type = 'bar'
    //this.myChart.time.unit = 'day'
    //this.myChart.options.scales.xAxes.time.unit = 'hour'
    //this.myChart.data.datasets[0].data = this.props.data.map(d => d.temp);
    //this.myChart.data.datasets[0].data = datax
    this.myChart.data.datasets[0].data = this.props.data.map(d => d.rain)
    //this.myChart.data.datasets[0].label = this.props.title
    this.myChart.data.datasets[1].data = this.props.data.map(d => d.accrain)
    this.myChart.update();
  }

  get_Data(logtype){
    var datax = []
    if(logtype === 'humid') {
      datax = this.props.data.map(d => d.humid)
    } else if (logtype === 'solar'){
      datax = this.props.data.map(d => d.solar)
    } else if (logtype === 'rain'){
      //graph_type = 'bar'
      datax = this.props.data.map(d => d.rain)
    } else if (logtype === 'wind'){
      datax = this.props.data.map(d => d.wind_speed)
    } else if (logtype === 'gatecounter'){
      datax = this.props.data.map(d => d.gatecounter)
    } else if (logtype === 'vsupply'){
      datax = this.props.data.map(d => d.vsupply)
    }
    else {
      datax = this.props.data.map(d => d.temp)
    }

    return datax 
  }

  componentDidMount() {
    //const datax = this.props.data.map(d => d.temp)
    //const datax = this.get_Data(this.props.logtype)
    const datax = this.props.data.map(d => d.rain)
    const accraindata = this.props.data.map(d => d.accrain)
    this.myChart = new Chart(this.canvasRef.current, {
      type: 'bar',
      options: {
              maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              type: 'time',
              time: {
                //parser: 'MM-DD-YYYYTHH:mm',
                tooltipFormat: 'll HH:mm',
                //unit: 'hour',
                distribution: 'series',
                unitStepSize: 2,
                displayFormats: {
                  'hour': 'HH:mm',
                  'day' : 'MMM D'
                },
              },
                ticks: {
                    major: {
                       enabled: true, // <-- This is the key line
                       fontStyle: 'bold', //You can also style these values differently
                       fontSize: 14 //You can also style these values differently
                    },
                    source: 'data',
                }
            }],
          yAxes: [
            {
              ticks: {
                min: 0,
                stepSize: 2,
              }
            }
          ]
        }
      },
      data: {
        labels: this.props.data.map(d => d.hours),
        datasets: [{
          //label: this.props.title,
          label: 'ฝน',
          //data: this.props.data.map(d => d.temp),
          data: datax,
          fill: 'none',
          backgroundColor: this.props.color,
          pointRadius: 2,
          borderColor: this.props.color,
          borderWidth: 1,
          lineTension: 0
        }, {
          type: 'line',
          label: 'ฝนสะสม',
          data: accraindata,
      }]
      }
    });
  }

  render() {
    
    return (
      
        <canvas ref={this.canvasRef} />
      
    )
    }
}


function ConvertToCSV(objArray) {
  var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
  var str = '';

  for (var i = 0; i < array.length; i++) {
      var line = '';
      var indexstr = '' ;
      for (var index in array[i]) {
          if (line != '') {
            line += ','
            indexstr += ','
          }
          
          if(i==0) {
            indexstr += index 
          }

          //console.log("index :", index)
          if (index === 'hours') {
            line += moment(array[i][index]).format('DD/MM/YYYY HH:mm')
          } else {
            line += array[i][index];
          }
      }
      if (i === 0 ) {
        str = indexstr + '\r\n'
      }
      str += line + '\r\n';
  }

  return str;
}


class BasinInfo extends React.Component {
    constructor() {
        super();

        this.state = {
            province: 'กรุงเทพมหานคร',
            station: 'กรุณาเลือก สถานี',
            station_id: '1',
            basin_id: "8",
            basin_id_sel: "8",
            basin_name: "N.1 ลุ่มน้ำน่าน",
            logType: 'ฝนสะสม',
            TypeLog: 'rain',
            Graph_Type: 'bar',
            stationdata: [],
            data: [],
            rain1h: [],
            station_info: [],
            rain1d: 0,
            rain24h: 0,
            date_start: moment().subtract(1, 'days').format('YYYY-MM-DDTHH:mm'),
            date_end: moment().format('YYYY-MM-DDTHH:mm'),
            time_type: 60,
            time_type_text: '1 ชั่วโมง',
            province_obj: []
        };
         

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleStationClick = this.handleStationClick.bind(this);
        this.handlelogTypeClick = this.handlelogTypeClick.bind(this);
        this.handleDateStartChange = this.handleDateStartChange.bind(this);
        this.handleDateEndChange = this.handleDateEndChange.bind(this);
        this.handleTimeTypeClick = this.handleTimeTypeClick.bind(this);
        this.handleDownload = this.handleDownload.bind(this);
        this.getInfo = this.getInfo.bind(this);
        this.handleLogOut= this.handleLogOut.bind(this);
    }
    
    handleLogOut(event) {
      //console.log("Logout Clicked!");
      localStorage.removeItem('user');
      //window.location(true);
      window.location.href = '/';
    }

    async getstationall() {
      try {
        const response = await fetch(`https://hydromet.rid.go.th/api/querybasinall`);
        const json = await response.json();
        this.setState({ station_info: json });
        
        //console.log("data :", this.state.data1[0])
      } catch (error) {
        console.log(error);
      }
      
    }

    getInfo(get_station_id) {
      
      var basin_id = this.state.basin_id_sel
      
      //console.log("mystation_id: ", station_id)
      var log_type = this.state.time_type
      var date_start = this.state.date_start
      var date_end = this.state.date_end
      const requestOptions = {
          method: 'POST',
          //headers: authHeader(),
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ basin_id, log_type, date_start, date_end })
      };

      //console.log("requestOptions: ", requestOptions)
      
      //fetch(`https://hydromet.rid.go.th/api/querybasinrawlog`, requestOptions).then(response => response.json())
      //.then(data => this.setState({ stationdata: data }));

      fetch(`https://hydromet.rid.go.th/api/querybasinlog_byid`, requestOptions).then(response => response.json())
      .then(data => this.setState({ stationdata: data }));
    }

    getBasinData(time_duration = '1h') {
     
      var basin_id = this.state.basin_id_sel
      const requestOptions = {
          method: 'POST',
          //headers: authHeader(),
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ basin_id, time_duration })
      };

      //console.log("requestOptions: ", requestOptions)
      if (time_duration == '1h') {
        fetch(`https://hydromet.rid.go.th/api/getbasindata`, requestOptions).then(response => response.json())
        .then(data => this.setState({ rain1h: data }));
      } else if (time_duration == '1d') {
        fetch(`https://hydromet.rid.go.th/api/getbasindata`, requestOptions).then(response => response.json())
        .then(data => this.setState({ rain1d: data }));
      } else {
        fetch(`https://hydromet.rid.go.th/api/getbasindata`, requestOptions).then(response => response.json())
        .then(data => this.setState({ rain24h: data }));
      }

      //console.log("After Fetch :", this.state.rain1h)
    }

      handleChange(event) {
        //console.log(event.target);
        this.setState({province: event.target.dataset.value})
        this.setState({station_id: ''})
        //console.log("station_id:", this.state.station_id)
        this.setState({station: ''})
      }

      handleClick(event) {
        //console.log(event.target.dataset.value);
        this.setState({province: event.target.dataset.value})
        this.setState({station: ''})
        
      }

      handleStationClick(event) {
        //console.log(event.target.dataset.value);
        this.setState({station: event.target.dataset.value})
        this.setState({station_id: event.target.dataset.item})
      }

      handleDateStartChange(event) {
        //console.log(event.target.value);
        
        //var date_start = event.target.value + '+07'
        var date_start= moment(event.target.value).format('YYYY-MM-DDTHH:mm')
        this.setState({date_start: date_start})
        //console.log(date_start);
        //this.setState({date_start: date_start})
        
        //this.setState({date_start: event.target.value + '+07'})
        
        //this.setState({station: event.target.dataset.value})
        //this.setState({station_id: event.target.dataset.item})
      }
      handleDateEndChange(event) {
        //console.log(event.target.value);
        var date_end = moment(event.target.value).format('YYYY-MM-DDTHH:mm')
        this.setState({date_end: date_end})
        //console.log(date_end);
        //this.setState({date_end: date_end})
        
        //this.setState({date_end: event.target.value + '+07'})
        
        //this.setState({station_id: event.target.dataset.item})
      }
      handleTimeTypeClick(event) {
        //console.log(event.target.dataset.value);
        
        this.setState({time_type: event.target.dataset.value})
        this.setState({time_type_text: event.target.dataset.item})
      }

      handlelogTypeClick(event) {
        //console.log(event.target.dataset.item);
        //var logtype = event.target.dataset.value
        
        this.setState({basin_name: event.target.dataset.item})
        this.setState({basin_id_sel: event.target.dataset.value})
      }
        
      async handleSubmit(event) {
        //console.log("Show Clicked:")
        
        this.getInfo()
        this.setState({basin_id: this.state.basin_id_sel})
        this.getBasinData('1h')
        this.getBasinData('1d')
        this.getBasinData('24h')
        //console.log(this.state);
        event.preventDefault();
      }

      handleDownload(event) {
       
        //console.log("Downloaded:")
        if (this.state.stationdata.length > 0) {
          var StationData = this.state.stationdata
          var csvData = ConvertToCSV(StationData)
          var FileSaver = require('file-saver');
          //console.log("station :", this.state.station)
          var blob = new Blob([csvData], {type: "text/csv;charset=utf-8"});
          FileSaver.saveAs(blob, "station-" + this.state.station_id + "-export.csv");
        }

        
        //console.log("csv", csvData)
        //this.getInfo()
        //console.log(this.state);
        //event.preventDefault();
      }

    componentDidMount() {
        this.props.getStationInfo()
        this.getstationall()
        this.getInfo()
        this.getBasinData('1h')
        this.getBasinData('1d')
        this.getBasinData('24h')
        //let params = queryString.parse(this.props.location.search)
        //console.log("query string:", params)
        //if (params) {
        //  this.setState({station_id: params.station_id}, () => { this.getInfo() })
          //this.getInfo(params.station_id)
        //}
        let items = []
        let obj = []
        let unique = []
        if (this.props.stations.items) { 
          items = JSON.stringify(this.props.stations.items)
          obj = JSON.parse(items)
          unique = [...new Set(obj.map(item => item.province))];
          //this.setState({province_obj: unique})
          //obj = obj.filter(station => station.station_id === params.station_id)
          //this.setState({station: "สถานีที่ " + obj.station_id + " " + obj.name})
      }
        
        //console.log("init province:", this.state.province_obj)
    }

    render() {
        const { stations} = this.props
        //let selected_province = this.state.province
        //let selected_station = this.state.station
        //let init_obj = null 
        var items = null 
        var obj = []
        //var selected_obj = []
        let unique = []
        var stationdata = this.state.stationdata
        if (stations.items) { 
            items = JSON.stringify(stations.items)
            obj = JSON.parse(items)
            //selected_obj = JSON.parse(items)
        }
        var rain_1h = this.state.rain1h
        var rain_24h = this.state.rain24h
        var rain_1d = this.state.rain1d
        var station_info = this.state.station_info
        
        var myobj = station_info

        if (myobj) {
          //unique = [...new Set(myobj.map(item => item.province))];
          myobj = myobj.filter(station => station.basinid === this.state.basin_id)
          {myobj.map((items2, index) =>
            { 
              //console.log("station_id :" + items2.station_id + " basinid: " + items2.basinid)
              
              
            }
          
          )}
        }

        var items2 = null 
        var rain1h = 0
        var rain1d = 0
        var rain24h = 0
        if (rain_1h)
        {
          {rain_1h.map((items2, index) =>
            { 
              //console.log("rain :", items2.rain)
              rain1h = items2.rain
            }
          
          )}
          
        }

        if (rain_24h)
        {
          {rain_24h.map((items2, index) =>
            { 
              //console.log("rain :", items2.rain)
              rain24h = items2.rain
            }
          
          )}
          
        }

        if (rain_1d)
        {
          {rain_1d.map((items2, index) =>
            { 
              //console.log("rain :", items2.rain)
              rain1d = items2.rain
            }
          
          )}
          
        }


        let site_img = 'Station' + this.state.station_id.padStart(3,"0") + ".jpg"

        try {
          var src = require('../sites/' + site_img) 
        }catch(err){
          var src=  require('../img/sites.jpg')
          //Do whatever you want when the image failed to load here
        }
/*
        if (selected_obj) {

          //if (this.state.station_id != "") {
            selected_obj = selected_obj.filter(station => station.station_id === this.state.station_id)
            {selected_obj.map((station, index) =>
              { 
                selected_station = "สถานีที่ " + station.station_id + " " + station.name
                selected_province = station.province
              }
            
            )}
          //} 
        }
*/
        if (obj) {
          unique = [...new Set(obj.map(item => item.province))];
         //console.log(obj);
          obj = obj.filter(station => station.station_id === "34" || station.station_id === "35" || station.station_id === "37" || station.station_id === "38" || station.station_id === "40")
        }
        
        //console.log('now() - 1day:', moment().subtract(1, 'days').format('MM/DD/YYYY HH:mm'))
        //let filteredStations = stations.items.filter(station => station.id === 5);
        //console.log(moment('2014-08-20 15:30:00').format('MM/DD/YYYY h:mm a'));
        //console.log("province = " + selected_province)
        //console.log("station_id = " + this.state.station_id)
        //console.log("TypeLog: ", this.state.TypeLog)
        //console.log("LogType: ", this.state.logType)
        //console.log("Selected station_id:", selected_obj)
        //console.log("init_object:", init_obj)
        //console.log("station_data = ", this.state.stationdata)
        //console.log("Data: ", this.state.data)
        //console.log("stations = " + obj)
        //const dateToFormat = new Date('1976-04-19T12:59-0500');
            
       
        return (

          <Fragment> 

            <nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-primary">
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
                    <a className="navbar-brand" href="#">Telemetry Report</a>
                    <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
                        <li className="nav-item">
                            <a className="nav-link active" href="./">หน้าหลัก</a>
                        </li>
                        
                    </ul>
    
                </div>
                <div >
                                <div className="btn-group dropdown">
                                     <button type="button" className="btn btn-primary dropdown-toggle" data-toggle="dropdown">
                                     <img className="img-circle" height="30px" src={require('../img/ico_usr.png')}></img>
                                         Login
                                         </button>
                                    
                                             <div className="dropdown-menu">
                                               <a className="dropdown-item" key={0} onClick={this.handleLogOut} data-value="ทั้งหมด" href="#">Logout</a>
                                              
                                             </div>   
                                           
                                 </div>
                </div>
            </nav>
          
          <div className="container pt-3">
          </div>
          
          <div className="container pt-5">
            <div className="row pb-8">

            <div className="col-lg-12 mx-auto">
               
                <div className="card ">
                        
                <div className="card-body p-2">
                
                    <div className="row">
                
                        <div className="col-lg-12 mx-auto">
                
                            <ul className="nav justify-content-center">
                               
                                  
                                  <li className="nav-item dropdown ml-2">
                                      <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">{this.state.basin_name}</a>
                                      <div className="dropdown-menu">
                                        <a className="dropdown-item" onClick={this.handlelogTypeClick} data-value="1" data-item="P.1 ลุ่มน้ำปิง" href="#">P.1 ลุ่มน้ำปิง</a>
                                        <div className="dropdown-divider"></div>
                                        <a className="dropdown-item" onClick={this.handlelogTypeClick} data-value="2" data-item="P.5 ลุ่มน้ำกวง">P.5 ลุ่มน้ำกวง</a>
                                        <div className="dropdown-divider"></div>
                                        <a className="dropdown-item" onClick={this.handlelogTypeClick} data-value="3" data-item="P.87 ลุ่มน้ำทา">P.87 ลุ่มน้ำทา</a>
                                        <div className="dropdown-divider"></div>
                                        <a className="dropdown-item" onClick={this.handlelogTypeClick} data-value="6" data-item="W.1C ลุ่มน้ำวัง">W.1C ลุ่มน้ำวัง</a>
                                        <div className="dropdown-divider"></div>
                                        <a className="dropdown-item" onClick={this.handlelogTypeClick} data-value="7" data-item="Y.1C ลุ่มน้ำยม">Y.1C ลุ่มน้ำยม</a>
                                        <div className="dropdown-divider"></div>
                                        <a className="dropdown-item" onClick={this.handlelogTypeClick} data-value="8" data-item="N.1 ลุ่มน้ำน่าน">N.1 ลุ่มน้ำน่าน</a>
                                        <div className="dropdown-divider"></div>
                                        <a className="dropdown-item" onClick={this.handlelogTypeClick} data-value="9" data-item="G.8 ลุ่มน้ำโขงเหนือ">G.8 ลุ่มน้ำโขงเหนือ</a>
                                        <div className="dropdown-divider"></div>
                                      
                                      </div>
                                  </li> 
                            
                    
                            </ul>            
                    
                        </div>
                    </div>

                    <div className="row mt-2">
                
                        <div className="col-lg-12 mx-auto">

                            <ul className="nav nav-pills justify-content-center">
                           
                                  <li className="nav-item mr-2">
                                    <input className="form-control " onChange={this.handleDateStartChange} value={this.state.date_start} type="datetime-local" id="example-datetime-local-input"></input>
                                  </li>
                                  <li className="nav-item ml-2">
                                    <input className="form-control" onChange={this.handleDateEndChange} value={this.state.date_end} type="datetime-local" id="example-datetime-local-input"></input>
                                  </li>
                                  <li className="nav-item dropdown">
                                      <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">{this.state.time_type_text}</a>
                                      <div className="dropdown-menu">
                              
                                        <a className="dropdown-item" onClick={this.handleTimeTypeClick} data-value="60" data-item="1 ชั่วโมง">1 ชั่วโมง</a>
                                        <div className="dropdown-divider"></div>
                                        <a className="dropdown-item" onClick={this.handleTimeTypeClick} data-value="1440" data-item="1 วัน">1 วัน</a>
                                        <div className="dropdown-divider"></div>
                                      </div>
                                  </li> 
                                  <li className="nav-item ml-2">
                                    <a className="btn btn-primary btn-sm" onClick={this.handleSubmit} href="#" tabIndex="-1" >ดึงข้อมูล</a>
                                  </li>

                                  <li className="nav-item ml-2">
                                    <a className="btn btn-success btn-sm" onClick={this.handleDownload} href="#" tabIndex="-1" >นำออกข้อมูล</a>
                                  </li>
                            </ul>

                        </div>
                    </div>

                    <div className="row">
                
                        <div className="col-lg-12 mx-0">
                            <div className="row">
                           
                                   <div className="col-lg-12 mx-auto">
                                      <div className="row">
                                        <div className="col-lg-9 mx-auto">
                                          <div className="card mt-2">
                                            <div className="card-body p-2">
                                              < MapContainer obj={myobj} />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-lg-3 mx-auto">
                                          <div className="card mt-2">
                                            <div className="card-body h-90">
                                              
                                    
                                              
                                                            <h6 className="card-title"><small><strong>ข้อมูลฝนสะสมล่าสุด</strong></small></h6>
                                                            <h6><small><p className="card-text"> 1 ชม. :  {rain1h}</p></small></h6>
                                                            <h6><small><p className="card-text"> 1 วัน (7.00น) :  {rain1d}</p></small></h6>
                                                            <h6><small><p className="card-text"> 24 ชม :  {rain24h}</p></small></h6>
                                                            <h6 className="card-title"><small><strong>{this.state.basin_name}</strong></small></h6>
                                                            <h6><small><p className="card-text">
                                                            { myobj.map((data1, index)=>{
                                                                var station_name
                                                                station_name = ''
                                                                if (index > 0) { station_name = ', ' }
                                                                station_name += 'สถานี ' +  data1.station_id + ' - ' + data1.name 
                                      
                                                                return (
                                                                    <Fragment>{station_name}</Fragment>
                                                                    );
                                                                
                                                               
                                                            }
                                                              
                                                            )}
                                                            </p></small></h6> 
                                            
                                              
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                          <div className="col mx-auto">
                                            <div className="card mt-2">
                                              <div className="card-body p-2">
                                                  
                                              {this.state.TypeLog !== 'rain' ? 
                                                  <BarGraph data={this.state.stationdata} title={this.state.logType} color="#3E517A" logtype={this.state.TypeLog} graphtype={this.state.Graph_Type}/>
                                                  : <BarGraph data={this.state.stationdata} title={this.state.logType} color="#3E517A" logtype={this.state.TypeLog} graphtype={this.state.Graph_Type}/>
                                                  }
                                                  
                                              </div>
                                            </div>
                                          </div>
                                      </div>
                                      

                                    </div>

                                   
                                   
                            </div>                           

                        </div>
                    </div>
              <div className="row m-2 mx-auto">
              <div className="table-responsive">
                  <table className="table table-fixed">
                      <thead>
                        <tr>
                            <th scope="col" className="col-2"><h6><small><strong>เวลา<br/>Time</strong></small></h6></th>
                            <th scope="col" className="col-1"><h6><small><strong>ฝนสะสม<br/>มม.</strong></small></h6></th>
                            <th scope="col" className="col-1"><h6><small><strong>ฝน<br/>มม.</strong></small></h6></th>
                            { myobj.map((data1, index) =>
                                              <th scope="col" className="col-1"><h6><small><strong>สถานี-{data1.station_id}<br/>มม.</strong></small></h6></th>
                                          )}
                           
                          </tr>
                        </thead>
                  </table>

                  <div className="custom-scrollbar-css">
                  <table className="table table-fixed">
                      <tbody>
                               
                                {stationdata &&
                                    <Fragment>
                                        {stationdata.map((data, index) =>
                                          <tr key={data.station_id}>
                                          <th scope="row" class="col-2"><h6><small><strong>{ <Moment format="YYYY-MM-DD HH:mm" date={data.hours}/>}</strong></small></h6></th>
                                          {Object.entries(data).map(([key,value])=>{
                                              if (key !== "hours") {
                                              return (
                                                <td className="col-1"><h6><small>{value}</small></h6></td>
                                                      );
                                              }
                                          })}
                                         
                                          
                                          
                                          
                                  
                                          </tr> 
                                        )}
                                     </Fragment> 
                                 }
                      </tbody>
                           
                  </table>
                  </div>
                            
             </div>
           </div>
                </div>

            </div>  
            
            </div>


            </div>
            </div>
            </Fragment> 
        );
    }
}

function mapState(state) {
    const { stations, authentication } = state;
    //const { user } = authentication;
    return { stations };
}

const actionCreators = {
    getStationInfo: stationActions.getStationInfo
}

const connectedBasinInfo = connect(mapState, actionCreators)(BasinInfo);
export { connectedBasinInfo as BasinInfo };